import * as React from 'react';

function SvgMegaphone(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 147.34 102.08"
      {...props}
    >
      <path
        d="M43.41 74.59l9.4 24.86a1.73 1.73 0 01-.75.26c-9.93.17-19.87.34-29.8.38a3.48 3.48 0 01-2.7-1.49c-3.26-8-6.35-16-9.72-24.63-4 .7-4.6-3.14-5.49-6.62-2.34-9.06-3.24-18.21-1.29-27.46 1.06-5 1.56-5.4 6.58-5.59 9.24-.34 18.48-.8 27.72-.84 11.11 0 21.11-3.67 30.3-9.38C82.6 14.79 90.34 10.6 96.03 5.02c1-1 3.78-3.66 5.78-2.88 1 .39 2 1.69 2.48 11.16.55 9.84-.21 13.49 1.92 19.8.71 2.1-.12 2 1.43 3.6 4.78 4.94 5.11 14.8.67 20a6.86 6.86 0 00-1.47 3.82c-.49 10.11-.76 20.23-1.24 30.35-.21 4.4-1.6 5-5.35 2.77-6.19-3.61-12.18-7.82-18.78-10.43-7.53-3-15.61-4.58-23.5-6.58-3.7-.93-7.52-1.41-11.29-2a24.88 24.88 0 00-3.27-.04zm58.87 18.42l1.1-.45c.61-13.83.81-28.61.41-44.23-.4-15.22-1.32-29.62-2.6-43.09a1.52 1.52 0 00-1-.66c-.76-.07-1.38.8-1.53.92-13.27 10.71-27.22 20.3-43.12 26.78a3.06 3.06 0 00-1.6 2.3c.27 11.09.72 22.18 1 33.27.16 5.66.08 5.66 5.56 6.71a96.84 96.84 0 0132.29 12.39c3.21 1.95 6.33 4.04 9.49 6.06zM6.92 72.2h47c-.59-12.45-1.19-25.24-1.79-37.93h-6.49c-12 .25-24 .72-36 .63-3.34 0-4.54 1-5.14 3.94-2.28 11.4-.7 22.45 2.42 33.36zm43.13 26.67a13.8 13.8 0 00-.61-1.9 54.88 54.88 0 01-6.55-20.94 3.38 3.38 0 00-2.5-2.06c-9.14-.15-18.28-.09-27.41-.07-.14 0-.28.22-.92.76 3 7.51 5.9 15.2 9.07 22.79.35.82 2 1.54 3 1.55 6.6.08 13.19-.06 19.78-.12 1.95-.02 3.88-.01 6.14-.01zm56.81-61.22v17.89c4.05-5.11 3.96-13.11 0-17.89z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M138.65 80.25c.19-.61.45-1.52.75-2.62 0 0 .74-2.69 1.34-5.36 4.11-18.26 2.45-31.24 2.45-31.24a72.87 72.87 0 00-2.68-12 78.93 78.93 0 00-3.73-9.47c-.71-1.52-1.36-2.79-1.86-3.74a35.62 35.62 0 015.6 6.54 38 38 0 014.67 10.28 57.32 57.32 0 011.87 21.49c-.46 6.07-1.2 14.6-6.54 23.36-.73 1.16-1.4 2.12-1.87 2.76zM122.77 65.3c.24-1 .59-2.62.91-4.54 1.24-7.36 2.11-12.53.47-18.1-.49-1.68-1.72-4.47-4.18-10.06-.55-1.25-1-2.27-1.34-3a25.91 25.91 0 015.07 5.77 26.25 26.25 0 013.57 8.59 27.39 27.39 0 01.11 10.83 25.79 25.79 0 01-2.74 7.68 24 24 0 01-1.87 2.83z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMegaphone;
