import TileThumbnail1 from '../assets/images/Tile1Thumbnail.png';
import TileThumbnail2 from '../assets/images/Tile2Thumbnail.png';
import TileThumbnail3 from '../assets/images/Tile3Thumbnail.png';
import TileThumbnail4 from '../assets/images/Tile4Thumbnail.png';
import TileThumbnail5 from '../assets/images/Tile5Thumbnail.png';

const BG_THUBMNAIL_MAP = {
  1: TileThumbnail1,
  2: TileThumbnail2,
  3: TileThumbnail3,
  4: TileThumbnail4,
  5: TileThumbnail5,
};

function BackgroundButton({ bg, onClick, size = 32 }) {
  const thumbnail = BG_THUBMNAIL_MAP[bg];
  const altText = `Background ${bg}`;
  return (
    <button onClick={onClick}>
      <img src={thumbnail} width={size} height={size} alt={altText} />
    </button>
  );
}

export default BackgroundButton;
