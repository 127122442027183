const API_URL = process.env.REACT_APP_API_URL || '';

export const addMailing = (contacts) => {
  return fetch(`${API_URL}/api/mailing`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contacts),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}

export const addContacts = (contacts) => {
  return fetch(`${API_URL}/api/contacts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contacts),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}

export const validateContacts = (contacts) => {
  return fetch(`${API_URL}/api/contacts/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contacts),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}
