import { memo, useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { DndProvider, useDrag, useDragLayer } from 'react-dnd';
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend';
import { renderWidget } from '../widgets';

const Container = ({ className, widgets }) => {
  const [maxzIndex] = useState(() =>
    widgets
      .map((x) => x.zIndex)
      .reduce((r, v) => Math.max(r, v), 0)
  );

  const [boxes, setBoxes] = useState(widgets);
  const moveBox = useCallback(
    (type, left, top) => {
      const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
      const newBoxes = boxes.map((w) =>
        w.type === type
          ? {
              ...w,
              left: clamp(left, 0, windowWidth - w.width),
              top: clamp(top, 0, windowHeight - w.height),
            }
          : w,
      );
      setBoxes(newBoxes);
    },
    [boxes],
  );
  const focus = useCallback(
    (type) => {
      const currentIndex = boxes.find((w) => w.type === type).zIndex;
      if (currentIndex === maxzIndex) {
        return;
      }
      const newBoxes = boxes.map((w) => ({
        ...w,
        zIndex:
          w.type === type
            ? maxzIndex
            : w.zIndex < currentIndex
            ? w.zIndex
            : w.zIndex - 1,
      }));
      setBoxes(newBoxes);
    },
    [boxes, maxzIndex],
  );
  const resize = useCallback(() => {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    const newBoxes = boxes.map((w) => ({
      ...w,
      left: clamp(w.left, 0, windowWidth - w.width),
      top: clamp(w.top, 0, windowHeight - w.height),
    }));
    setBoxes(newBoxes);
  }, [boxes]);
  useLayoutEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [resize]);

  const [once, setOnce] = useState(false);
  useEffect(() => {
    if (!once) {
      resize();
      setOnce(true);
    }
  }, [once, resize]);
  return (
    <div className={className}>
      {boxes.map((w) => (
        <DraggableBox key={w.type} {...w} moveBox={moveBox} focus={focus} />
      ))}
    </div>
  );
};

function getStyles(left, top, isDragging, zIndex) {
  const transform = `translate3d(${left}px, ${top}px, 0)`;
  return {
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
    zIndex,
  };
}

const DraggableBox = memo(function DraggableBox(props) {
  const { type, left, top, width, height, moveBox, zIndex, rem, focus } = props;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: type,
      item: { left, top, width, height, type, rem },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(type, left, top);
      },
    }),
    [type, left, top, moveBox],
  );
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return (
    <div
      className="absolute"
      style={getStyles(left, top, isDragging, zIndex)}
      onMouseDown={() => focus(type)}
    >
      {renderWidget({
        dragRef: drag,
        inline: false,
        item: { type, left, top, width, height, rem },
      })}
    </div>
  );
});

const clamp = (x, a, b) => Math.min(Math.max(x, a), b);

function getItemStyles(initialOffset, currentOffset, item) {
  if (!initialOffset || !currentOffset || !item) {
    return {
      display: 'none',
    };
  }
  const { width, height } = item;
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  let { x, y } = currentOffset;
  x = clamp(x, 0, windowWidth - width);
  y = clamp(y, 0, windowHeight - height);
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const CustomDragLayer = ({ className }) => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  );
  if (!isDragging) {
    return null;
  }
  return (
    <div className={className}>
      <div style={getItemStyles(initialOffset, currentOffset, item)}>
        {renderWidget({ inline: true, item })}
      </div>
    </div>
  );
};

function WidgetBoard({ widgets }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Container className="relative w-full h-full" widgets={widgets} />
      <CustomDragLayer className="fixed w-full h-full pointer-events-none z-50 inset-0" />
    </DndProvider>
  );
}

export default WidgetBoard;
