import * as icons from '../icons';
import { Context } from '../context';
import { Modals } from '../modals';
import { Particles, Copyright, WidgetBoard } from '../components';
import { widgetRelAt } from './Windows';
import { Types as WidgetTypes } from '../widgets';

function Windows(props) {
  return (
    <Context {...props}>
      <div className="bg-quotables-light-blue h-full w-full">
        <Particles className="absolute inset-0 bg-quotables-light-blue" />
        <icons.Brand className="absolute h-12 w-40 left-4 top-2" />
        <Copyright className="absolute bottom-px left-1/2 transform -translate-x-1/2 text-xs" />
        <WidgetBoard widgets={[
          { ...widgetRelAt(415, 65, WidgetTypes.ComingSoon), zIndex: 0 },
        ]} />
        <Modals />
      </div>
    </Context>
  );
}

export default Windows;
