import * as React from 'react';

function SvgCheckMark(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 134.77 176.16"
      {...props}
    >
      <path
        d="M132.98 40.78c-.16-1.53-.56-3.52-.71-7a9.28 9.28 0 00-.24-2.63c-1-3.07-4.93-4.32-6.23-4.83C71.22 4.97 70.16 4.62 68.05 3.93c-3.07-1-5.54-1.84-7-2.32a73.75 73.75 0 00-7.94 3.35c-2.4 1.18-4.1 2.19-4.7 2.53-10.52 6.14-26.33 12.28-44.6 24.29-1.13.74-2 1.34-2.32 1.54v1.47C3.15 78.15 4.04 76.2 4.31 93.55c.07 4.12.42 8.14.82 13.93a54.72 54.72 0 004.2 17.7c2.75 6.44 6 10.59 9.8 15.19 8.3 10.08 16.32 15.69 27.62 23.59a42.9 42.9 0 0016.27 9.15 37.34 37.34 0 0010.08 1.57 35 35 0 0011.79-2.08c12.39-4.21 21.52-15.37 23.64-18 9.29-9.61 15.49-16.15 19.61-27a62.07 62.07 0 003.91-19.79c.32-8.71.64-17.42.73-26.13.17-8.33.9-34.39.2-40.9zm-6.81 73.32c-.13 1.86-.18 2.89-.42 4.5-2.29 15.45-17.49 30.45-17.49 30.45s-11.67 12-27.35 17.94a25 25 0 01-6.1 1.52 29.6 29.6 0 01-8.65-.5c-5.51-1.17-17.92-7.78-32.53-19.35 0 0-17.66-12.7-23.18-33.81-.56-2.15-.21-1.37-1.75-14.11C7.54 66.08 6.81 40.5 6.94 39.49a6.75 6.75 0 01.45-1.66 5.51 5.51 0 01.29-.66A10.17 10.17 0 0110 34.08L59.52 9.35l2-1c4.75 1.86 8.63 3.24 11.11 4.09 0 0 36.84 12.65 50.27 18.56.66.29 2.8.87 3.81 2.72a5.52 5.52 0 01.41 1.07c3.49 13.68-.95 79.31-.95 79.31z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        d="M63.21 130.88c-25.49-21.7-35.24-29.67-36.2-30a6.39 6.39 0 01-1.9-1.06c-.53-.37-.69-.56-.77-.77-.31-.77.31-1.68.52-2 2.93-4.44 3.93-6.42 6.44-9.29.73-.83 3.35-.94 4.51-.29a65.71 65.71 0 018.52 6c3.76 3 7.43 6 11.08 9.14a2 2 0 001.5.65c.75-.12 1.19-1 1.31-1.18 3.2-5.81 44.92-49.07 49.71-53.25a3.83 3.83 0 013-1.22c.88.17 1.64 1 2.49 2.47 2.07 3.6 3.54 6.56 5.31 10 .66 1.29.72 1.36.82 1.58 3.4 7.58-24.21 27.09-44.76 52.81a174 174 0 00-11.58 16.41zM62 127.01c2.27-3.74 4.22-6.55 5.29-8.06 0 0 14.3-20.08 47-53.47a5.25 5.25 0 001.8-3.65c0-.61-.15-1-2.85-5.32-1.26-2 .28.47-2.23-3.22-.75-1.1-1.1-1.67-1.75-1.79a2.86 2.86 0 00-2.33 1.05c-2.74 2.6-43.65 45.14-48 52.42a2.27 2.27 0 01-1.39 1.16c-.43.07-.94-.14-1.66-.71-6.61-5.32-13.34-10.47-20.14-15.55a3 3 0 00-2.89.32c-.54.58-1.47.93-4.3 5.74 0 0-.07.12-.4.8s-.47 1.07-.35 1.28a.64.64 0 00.35.27c.84.27 19.85 17.11 23.72 20.48 2.25 1.93 5.69 4.84 10.13 8.25z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
    </svg>
  );
}

export default SvgCheckMark;
