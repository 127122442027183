import { memo, useContext } from 'react';
import * as icons from '../icons';
import { DispatchContext } from '../context';
import { Widget } from './Base';

function MailingWidget({ item: { width, height, rem }, dragRef, inline }) {
  const { showMailing } = useContext(DispatchContext);
  return (
    <Widget width={width} height={height} inline={inline}>
      <div
        className="cursor-move h-full py-2 text-quotables-blue bg-white flex flex-row justify-center items-center"
        ref={dragRef}
        style={{ padding: `${0.5 * rem}px 0` }}
      >
        <button
          className="h-8 w-8 focus:outline-none cursor-pointer"
          onClick={showMailing}
          style={{ width: `${2.0 * rem}px`, height: `${2.0 * rem}px` }}
        >
          <icons.Mail />
        </button>
      </div>
    </Widget>
  );
}

export default memo(MailingWidget);
