import React from 'react';
import ReactModal from 'react-modal';

export const Modal = ({ children, className, ...rest }) => (
  <ReactModal
    className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white max-w-full max-h-full rounded shadow-2xl outline-none ${
      className ? className : ''
    }`}
    overlayClassName="fixed inset-0 bg-black bg-opacity-25 z-10"
    bodyOpenClassName="overflow-hidden"
    htmlOpenClassName="overflow-hidden"
    {...rest}
  >
    {children}
  </ReactModal>
);

export default Modal;
