import * as React from 'react';

function SvgExpand1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 86.16 87.42"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1" fill="currentColor">
          <path d="M65.38 15.71c-4.65 3.59-5.44 3.95-10.16 7.6a2.19 2.19 0 00-.33 3.16l.57.68a2.65 2.65 0 003.8.24 82.93 82.93 0 019.56-7.82 39.18 39.18 0 015 9.37 1.78 1.78 0 001.52 1.75 1.69 1.69 0 001.2-.55 48.36 48.36 0 002.24-4.82c1.86-4.67 1.93-7.09 3.27-13.09C84.05 3.44 85.36 2 84.21.66s-2.75-.27-12.45.51c0 0-4.47.36-15.17 3.4-.31.09-1 .3-1.14.79-.32 1.11 2.41 2.49 5.09 4.8a26.74 26.74 0 014.84 5.55zM8.07 24.15c.39 0 .77-.37 1.44-1a7.11 7.11 0 001.62-2.3 50.45 50.45 0 013.63-5.55c5.25 4.89 5.82 5.56 9.51 9.13a2.57 2.57 0 003.73-.18 2.2 2.2 0 00-.19-3.08l-2.49-2.24c-4.22-3.83-3.86-3.32-7.94-7.12C21 9.16 23.3 6.37 24.85 5.22c.29-.22.92-.7.84-1.17s-.71-.66-1.11-.78C19.12 1.62 7.79 2 7.79 2c-5.08-.13-6.74-.53-7.51.63-.9 1.37.51 3.11 2.26 7.24C6 18.06 6.34 24.18 8.07 24.15zM33 71.88l-13-3.8q3.62-4.66 7.25-9.47c2.45-3.24.49-.61 2.85-3.94a1.67 1.67 0 00-.34-2.28l-.85-.66a1.08 1.08 0 00-1.5.18c-4.15 5.3-5.09 5.72-11.74 14.29L3.88 63a3.85 3.85 0 00-1.24.62A2.29 2.29 0 002 64.88C1.56 67 5.86 75.86 7.5 79.79c2.06 4.92 1.89 6.79 3.68 7.46s3.25-.82 8.08-3.67c2.21-1.3 13.68-7.92 14.31-9.84a1.75 1.75 0 000-1.24zM85.79 61.94l-.17-.09a2.3 2.3 0 00-1.82.15c-1.81.83-6.54 3.74-8.71 4.91-.57.3-1.42.74-2.49 1.22-5.66-8.39-6.8-10.23-11.12-16.58a1.86 1.86 0 00-2.6-.48l-.78.55a3.49 3.49 0 00-.9 4.82c3.78 5.6 4.67 7 9.81 14.76a19.65 19.65 0 01-3 2.25c-2.65 1.64-5.5 3.44-7.59 4.51a2.46 2.46 0 00-1.25 1.23 2.17 2.17 0 00-.07 1c.34 2 10.3 3.55 21.11 6.54 1.68.47 3.84 1.12 5.57 0 2-1.24 1.83-3.75 2.53-8 1.37-8.34 2.45-16.21 1.48-16.79z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgExpand1;
