const contractAddr = process.env.REACT_APP_CONTRACT_ADDR;
const openSeaUrl = `https://testnets.opensea.io/assets/${contractAddr}`;

export function OpenSeaLink({ children, id, ...props }) {
  return (
    <a href={`${openSeaUrl}/${id}`} target="_blank" rel="noreferrer" {...props}>
      {children}
    </a>
  );
}

export default OpenSeaLink;
