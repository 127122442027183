import * as icons from '../icons';
import { Context } from '../context';
import { Modals } from '../modals';
import { Particles, Copyright, WidgetBoard, Countdown } from '../components';
import { widgets } from './Windows';

function Windows(props) {
  return (
    <Context {...props}>
      <div className="bg-quotables-light-blue h-full w-full">
        <Particles className="absolute inset-0 bg-quotables-light-blue" />
        <icons.Brand className="absolute h-12 w-40 left-4 top-2" />
        <Countdown className="absolute w-auto bottom-8 left-1/2 transform -translate-x-1/2 p-2 bg-white rounded shadow-xl" />
        <Copyright className="absolute bottom-px left-1/2 transform -translate-x-1/2 text-xs" />
        <WidgetBoard widgets={widgets} />
        <Modals />
      </div>
    </Context>
  );
}

export default Windows;
