const API_URL = process.env.REACT_APP_API_URL || '';

export const getAllTiles = () => {
  return fetch(`${API_URL}/api/tiles`, {
    method: 'GET',
  })
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject({
          message: resp.statusText,
          status: resp.status,
        });
      }
      return resp.json();
    })
    .then((data) =>
      data.map((it) => ({
        ...it,
        image: `${API_URL}/storage/${it.image}`,
        thumbnail: `${API_URL}/storage/${it.thumbnail}`,
      })),
    );
};

export const getTile = (tileId, withOwner = true) => {
  const params = new URLSearchParams();
  if (withOwner) params.set('withOwner', withOwner);
  return fetch(`${API_URL}/api/tiles/${tileId}?${params}`, {
    method: 'GET',
  })
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject({
          message: resp.statusText,
          status: resp.status,
        });
      }
      return resp.json();
    })
    .then((it) => ({
      ...it,
      image: `${API_URL}/storage/${it.image}`,
      thumbnail: `${API_URL}/storage/${it.thumbnail}`,
    }));
};

export const getTileList = (values) => {
  const requests = values.map((tileId) => getTile(tileId, false));
  return Promise.all(requests);
};

export const validateText = (text) => {
  const params = new URLSearchParams();
  params.set('text', text);
  return fetch(`${API_URL}/api/tiles/validate?${params}`, {
    method: 'GET',
  })
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject({
          message: resp.statusText,
          status: resp.status,
        });
      }
      return resp.json();
    })
    .then((data) => {
      if (data.error) {
        return Promise.reject({
          message: data.error.text,
        });
      }
      return data;
    });
};

export const createTileDebug = (text, bg) => {
  return fetch(`${API_URL}/api/tiles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text,
      bg,
    }),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
};

export const getMeta = () => {
  return fetch(`${API_URL}/api/meta`, {
    method: 'GET',
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
};
