import React from 'react';

function getBrowserVisibilityEventName() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

function getBrowserHiddenPropName() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

const visibilityEventName = getBrowserVisibilityEventName();
const hiddenPropName = getBrowserHiddenPropName();

export function getIsDocumentHidden() {
  return !document[hiddenPropName];
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    document.addEventListener(visibilityEventName, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityEventName, onVisibilityChange);
    };
  });

  return isVisible;
}

export default usePageVisibility;
