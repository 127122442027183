import { useRef, useState, useEffect } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Link } from 'react-router-dom';
import Thumbnail0 from '../assets/images/Tile0Thumbnail.png';
import Tile0Tiny from '../assets/images/Tile0blur.png';
import Tile1Tiny from '../assets/images/Tile1blur.png';
import Tile2Tiny from '../assets/images/Tile2blur.png';
import Tile3Tiny from '../assets/images/Tile3blur.png';
import Tile4Tiny from '../assets/images/Tile4blur.png';
import Tile5Tiny from '../assets/images/Tile5blur.png';
import * as api from '../api';
import Loading from './Loading';

const tinyTiles = {
  0: Tile0Tiny,
  1: Tile1Tiny,
  2: Tile2Tiny,
  3: Tile3Tiny,
  4: Tile4Tiny,
  5: Tile5Tiny,
};

function getThumbnailUrl(size, { free, bg, thumbnail }) {
  if (size === 'tiny') {
    if (free) {
      return tinyTiles[0];
    }
    return tinyTiles[bg];
  } else if (size === 'small') {
    if (free) {
      return Thumbnail0;
    }
    return thumbnail;
  }
}

function ImageGrid({
  size,
  data,
  onItemClick,
  selected,
  rows = 32,
  cols = 64,
}) {
  const tileSize = size === 'tiny' ? 24 : 196;
  const gridRef = useRef();
  useEffect(() => {
    if (selected !== undefined && gridRef.current !== undefined) {
      const rowIndex = Math.floor(selected / cols);
      gridRef.current.scrollToItem({
        columnIndex: selected - rowIndex * cols,
        rowIndex: rowIndex,
        align: 'smart',
      });
    }
  }, [gridRef, size, selected, cols]);
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Grid
          columnCount={cols}
          columnWidth={tileSize}
          height={height}
          rowCount={rows}
          rowHeight={tileSize}
          width={width}
          ref={gridRef}
        >
          {({ columnIndex, rowIndex, style }) => {
            const idx = rowIndex * cols + columnIndex;
            const it = data[idx];
            return (
              <img
                className={classNames('tile', {
                  'tile-tiny': size === 'tiny',
                  'tile-small': size === 'small',
                  selected: idx === selected,
                })}
                src={getThumbnailUrl(size, it)}
                width={tileSize}
                height={tileSize}
                alt={it.text}
                style={style}
                onClick={() => onItemClick(idx)}
              />
            );
          }}
        </Grid>
      )}
    </AutoSizer>
  );
}

const range = (start, end) => {
  return Array.from({ length: end - start }, (_, i) => start + i);
};

const emptyMap = range(0, 2048).map((i) => ({
  id: i,
  bg: 0,
  free: true,
  thumbnail: Thumbnail0,
  text: 'free',
}));

function TileMap() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(emptyMap);
  useEffect(() => {
    setLoading(true);
    api
      .getAllTiles()
      .then((d) => {
        for (let i = d.length; i < 2048; i++) {
          d.push({
            id: i,
            bg: 0,
            free: true,
            thumbnail: Thumbnail0,
            text: 'free',
          });
        }
        setLoading(false);
        setData(d);
      })
      .catch(() => emptyMap);
  }, []);
  const [size, setSize] = useState('tiny');
  const [selected, setSelected] = useState(undefined);
  const selectedItem = data[selected];
  const onItemClick = (idx) => {
    if (selected !== idx) setSelected(idx);
    else if (size === 'tiny') setSize('small');
    else setSize('tiny');
  };
  return (
    <div className="h-full relative">
      {loading ? <Loading /> : null}
      <ImageGrid
        size={size}
        data={data}
        onItemClick={onItemClick}
        selected={selected}
      />
      {selected !== undefined && (
        <div className="flex flex-col items-center p-2 bg-gray-300 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
          <img
            src={getThumbnailUrl('small', selectedItem)}
            alt={selectedItem.text}
          />
          {selectedItem.free ? (
            <Link to="/buy">Buy</Link>
          ) : (
            <a href={selectedItem.image}>View</a>
          )}
        </div>
      )}
    </div>
  );
}

export default TileMap;
