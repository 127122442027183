import { createContext, useState, useEffect } from 'react'
import * as api from './api'

export const StateContext = createContext();
export const DispatchContext = createContext();

const backgrounds = [1, 2, 3, 4, 5];

const quotes = [
  'Everything should be made as simple as possible, but no simpler.',
  'To NFT or not to NFT, that is the question.',
  'Be yourself; everyone else is already taken.',
  'We accept the love we think we deserve.',
  'If life were predictable it would cease to be life, and be without flavor.',
  'Life is what happens when you\'re busy making other plans.',
];

const getRandomElement = (arr) => {
  const idx = Math.floor(Math.random() * arr.length);
  return arr[idx];
};

const initialState = {
  currentModal: null,
  message: null,
  numTiles: null,
  randomTile: {
    title: getRandomElement(quotes),
    bg: getRandomElement(backgrounds),
  },
  stage: "initial",
  previewId: null,
};

export function Context({ children, ...props }) {
  const [state, setState] = useState({
    ...initialState,
    ...props,
  });
  const setStage = name => setState(value => ({
    ...value,
    stage: name,
  }));
  const show = name => setState(value => ({
    ...value,
    currentModal: name,
    message: null,
    previewId: null,
  }));
  const onHide = () => show(null);
  const dispatches = {
    showFaqModal: () => show("faq"),
    showIntroModal: () => show("intro"),
    showWallModal: () => show("wall"),
    showWalletModal: () => show("wallet"),
    showBuyModal: () => show("buy"),
    showAlert: (message) => setState(value => ({
      ...value,
      currentModal: 'alert',
      message: message,
      previewId: null,
    })),
    showBuySucceed: () => show("buy_succeed"),
    showMailing: () => show("mailing"),
    show,
    onHide,
  };
  useEffect(() => {
    if (state.stage === "initial") {
      setStage("loading-num-tiles");
      api.getMeta()
        .then(data => {
          setState(value => ({
            ...value,
            numTiles: data.num,
            stage: "loaded-num-tiles",
          }));
        })
        .catch(err => {
          setStage("error");
          console.log(err);
        });
    } else if (state.stage === "loaded-num-tiles" && state.numTiles) {
      setStage("loading-random-tile");
      const tileId = 1 + Math.floor(Math.random() * state.numTiles);
      api.getTile(tileId)
        .then(data => {
          setState(value => ({
            ...value,
            randomTile: data,
            stage: "loaded-random-tile",
          }));
        })
        .catch(err => {
          setStage("error");
          console.log(err);
        });
    }
  }, [state]);
  return (
    <DispatchContext.Provider value={dispatches}>
      <StateContext.Provider value={state}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}
