import { useContext, memo } from 'react';
import { DispatchContext } from '../context';
import { Widget, Header, ExpandButton } from './Base';

function IntroWidget({ item: { width, height, rem }, dragRef, inline }) {
  const { showIntroModal } = useContext(DispatchContext);
  return (
    <Widget width={width} height={height} inline={inline}>
      <div className="h-full text-quotables-blue bg-white flex flex-col">
        <div
          className="flex flex-row p-4 justify-between items-center cursor-move"
          ref={dragRef}
          style={{ padding: `${1.0 * rem}px` }}
        >
          <Header rem={rem}>Introducing Quotables</Header>
        </div>
        <div
          className="text-xs p-2 leading-relaxed text-quotables-light-gray cursor-pointer flex-grow text-center"
          style={{
            padding: `${0.5 * rem}px`,
            fontSize: `${0.75 * rem}px`,
            lineHeight: `${1.625 * rem}px`,
          }}
          onClick={showIntroModal}
        >
          The Quotables are NFT versions of the traditional Dutch tile sayings,
          also known as “tegelspreuken”. These tiles have an interesting
          history, combining the art of tin-glazed pottery and the love for
          proverbs and aphorisms deeply rooted in Dutch culture. We're offering
          the experience of making your own tile with your favorite quote, turn
          in into an NFT and put it up on the Quotawall. Share the wisdom and
          spread the word!
        </div>
        <div
          className="flex flex-row pb-4 pr-4 items-center justify-end cursor-pointer"
          onClick={showIntroModal}
          style={{
            paddingBottom: `${1.0 * rem}px`,
            paddingRight: `${1.0 * rem}px`,
          }}
        >
          <ExpandButton
            className="bg-quotables-blue text-white"
            onClick={showIntroModal}
            rem={rem}
          />
        </div>
      </div>
    </Widget>
  );
}

export default memo(IntroWidget);
