import * as React from 'react';

function SvgExpandSign(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 170.88 161.01"
      {...props}
    >
      <path
        d="M168.05 147.03c-1.08 6.94-.83 11-4 13-2.79 1.78-6.27.74-9 0-17.45-4.72-33.54-7.19-34.11-10.35a3.54 3.54 0 01.11-1.65 4 4 0 012-2c3.37-1.74 8-4.68 12.21-7.34a31.81 31.81 0 004.79-3.66c-37.93-56.43-48.59-71.39-50-72.61l-2.93-2.37-5 4.07-1.47 1.19C69.32 74.48 55.9 91.9 55.9 91.9q-7.41 10.31-15.23 20.78-5.82 7.78-11.62 15.35l21 6 1 1a2.85 2.85 0 010 2c-1 3.1-19.45 13.88-23 16-7.77 4.64-10 7.09-13 6s-2.64-4.08-6-12c-2.68-6.32-9.7-20.58-9-24a3.79 3.79 0 011-2 6.54 6.54 0 012-1l19 5C53.6 83.89 65.98 68.15 68.71 65.14c1.51-1.66 2.89-3.43 4.43-5.05 1.69-1.77 3-3.84 4.65-5.7a2.62 2.62 0 00.81-1.44c.19-1.59-1.68-3.06-2.78-3.85-3.13-2.24-18.15-12-38.77-25.07-2.46 5.56-3.58 7.81-4.11 9.87a11.4 11.4 0 01-1.89 4.13c-.88 1.19-1.38 1.87-2 2-2.73.57-5.08-9-13-21-4-6-6.74-8.39-5.73-10.78.88-2.06 3.64-1.93 11.73-3.22 0 0 17.85-4 27-3 .67.08 1.73.25 2 .9s-.59 1.68-1 2.1c-2.11 2.28-4.93 7.4-9.87 12.67q11.4 7.23 23.13 14.46 12.19 7.52 24.21 14.69l44.05-25a43.15 43.15 0 00-6.45-10.03c-3.76-4.3-7.81-7.12-7.05-8.82.34-.76 1.48-.93 2-1 17.78-2.43 25-2 25-2 15.67.94 18.46-.49 20 2s-.87 4.4-6 18c-3.49 9.29-4.16 13.12-8.19 20.17a74.26 74.26 0 01-4.67 7.18 2.64 2.64 0 01-2 .61 2.88 2.88 0 01-2-3.14 62.43 62.43 0 00-2.28-7.89 64.06 64.06 0 00-3.64-8.21 240 240 0 00-30.92 19.22c-1.62 1.18-4 3-7 5.24a2.34 2.34 0 00-1.25 1.4c-.3 1.3 1.22 2.65 1.45 2.87 2.07 2 21.44 30 50.53 72.55 1.72-.78 3.09-1.5 4-2 3.5-1.9 11.1-6.65 14-8a3.79 3.79 0 012.94-.32l.27.14c1.53 1-.14 13.66-2.26 27.21z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgExpandSign;
