import * as React from 'react';

function SvgExpand4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 82.67 82.9"
      fill="currentColor"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M63.4 15.71c-4.65 3.59-5.43 3.95-10.16 7.6a2.2 2.2 0 00-.33 3.16l.57.68a2.65 2.65 0 003.8.24 82.72 82.72 0 019.57-7.82 39.6 39.6 0 015 9.37 1.79 1.79 0 001.52 1.75 1.67 1.67 0 001.2-.55 46.18 46.18 0 002.24-4.82c1.86-4.67 1.94-7.09 3.27-13.09C82.08 3.44 83.39 2 82.23.66s-2.75-.27-12.45.51c0 0-4.47.36-15.17 3.4-.31.09-1 .3-1.14.79-.32 1.11 2.42 2.49 5.09 4.8a26.74 26.74 0 014.84 5.55zM29.16 71.71l-12-6.26q4.47-3.87 9-7.88c3-2.69.6-.5 3.56-3.3a1.67 1.67 0 00.09-2.27l-.71-.81a1.06 1.06 0 00-1.51-.19c-5.1 4.38-6.1 4.6-14.31 11.71L2.37 57.34A4 4 0 001 57.7a2.32 2.32 0 00-.85 1.09c-.86 2 1.63 11.53 2.47 15.7 1.05 5.23.52 7 2.14 8s3.35-.17 8.64-2c2.42-.84 15-5.09 16-6.85a1.79 1.79 0 00.25-1.21z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgExpand4;
