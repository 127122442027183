import * as React from 'react';

function SvgDecoration2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.54 119.53"
      {...props}
    >
      <defs>
        <style />
      </defs>
      <g id="Decoration2_svg__Layer_2" data-name="Layer 2">
        <g
          id="Decoration2_svg__Layer_1-2"
          data-name="Layer 1"
          fill="currentColor"
        >
          <path d="M89.81 81.78a28.06 28.06 0 005-2.28 24.69 24.69 0 006.57-5.72 38 38 0 01-5.14.57 35.85 35.85 0 01-10.75-1.15A27.66 27.66 0 0174 66.91c2 .29 3.52.59 4.61.81s1.59.36 2.54.51a35.88 35.88 0 004 .4c7.21.3 13.8-2.27 16-3.17s1.89-.94 4.49-2c2.31-.92 4.38-2.22 6.76-3 .32-.11 1.14-.38 1.15-.75s-.38-.56-1.15-.79c-4.29-1.31-8-3.16-12-4.72-2.18-.84-10.63-4.24-19.88-2.66a27.08 27.08 0 00-7.05 2.22 18.58 18.58 0 0110.06-6.36 35.73 35.73 0 0110.9-.5c1.24.11 5.44.57 5.44.57a23.48 23.48 0 00-11.12-8.26c-8.75-3.07-22.27-1-32.17 6.92-1.56 1.25-1.07 1.07-3.45 3.06-10.78 9-17.42 9.75-17.26 12.58.13 2.45 5.13 2.61 16.54 9.72 2.61 1.63 2.26 1.57 4.22 2.78 10.49 6.47 24.09 10.45 33.18 7.51zM68.27 40.57h-.16a.47.47 0 000-.1h.17s-.02.08-.01.1zM5.53 40.71a11.29 11.29 0 01-5.48-8.44c-.37-3.57 1.4-6.64 3.86-10.64.8-1.3 1.72-2.56 2.6-3.83 3-4.38 3.47-9.13 2.75-14a4.36 4.36 0 00-.15-1.2C8.57.9 6.74.1 6.82 0a10.24 10.24 0 013.58 2.19s.54.47 1 .93c2.2 2.31 3.33 7.12 3.46 7.67a7.7 7.7 0 014.82-3.92 9.36 9.36 0 013.37 0A9.14 9.14 0 0020.33 8a3.63 3.63 0 00-1.24 2.69 8.76 8.76 0 000 2 14.68 14.68 0 001.28 4.44c3.05 6.1 6.62 9.51 6.76 13.73.11 3.6-2.3 7.1-5.41 9.15-4.42 2.91-11.27 3.59-16.19.7zM16.28 119.53a5.38 5.38 0 002-2.29c1.77-4.28-4.52-11-9.34-14.54 0 0-5.64-4.17-6.74-7.31-.29-.82-.49-1.67-.49-1.67a18.74 18.74 0 01-.38-2.13A11.13 11.13 0 014 83.77a13.56 13.56 0 018.09-4.14c4.91-.63 10.13 1.2 12.58 4.54 3.43 4.66.44 10.91-.06 12-1.08 2.24-2.51 3.69-2.89 6.8a8.61 8.61 0 00.57 4.69 6.88 6.88 0 003.44 3.89c.67.28 1.39.43 1.36.57s-1.52.26-2.71 0a6.86 6.86 0 01-4.05-2.86 7.12 7.12 0 010 6.86 8.75 8.75 0 01-4.05 3.41z" />
          <path d="M34.34 85.65c-5.19-2.58-11.92-6.09-20.44-8-.58-.14-2.82-.63-2.75-.85s5.87.08 9.52.59c12.16 1.7 21.68 7.39 31.83 13.46A65.77 65.77 0 0060.59 95C70.9 99.5 76 98.72 77.21 98.49A6.68 6.68 0 0080.73 97c1.86-2-1.1-4.87-1.25-5 3 .36 5.38 1.74 6.13 3.52.84 2-.77 3.65-1 3.92a11.22 11.22 0 01-4.28 2.5c-3.27 1-9.67 1.8-17.42-.4C56 99.57 55.5 97.41 40.47 89c-2.82-1.61-4.23-2.4-6.13-3.35zM10.85 46.64c-.21-.35 3.62-1.58 13.25-5.52 5-2.06 8.14-3.32 12-5.13 2.87-1.33 7.62-3.54 13.28-6.81 3.37-1.94 6.07-3.69 11.13-6.11 2.13-1 3.19-1.53 4.23-1.9 1.71-.62 11.54-4 17.2-.83.25.15 3.55 2 3.15 4.69-.34 2.35-3.26 4.45-7.4 5.24a7.81 7.81 0 001.53-1.78c.51-.84 1.32-2.16.57-3.25a4.44 4.44 0 00-2.49-1.38C73.15 22.8 67 25.94 60.06 29.4c-7.2 3.6-10.8 5.4-12.62 6.23a150.23 150.23 0 01-22.17 8c-6.64 1.87-14.14 3.47-14.42 3.01zM8.41 65.87C4 63.18.78 63.1.74 61.7s3.33-1.58 7.67-4.63c4.71-3.31 4.72-6 7.11-6 3-.09 3.4 4.12 9.21 7 4.1 2 7.73 1.78 7.76 2.74s-3.77.88-7.65 3.15c-5.49 3.22-5.19 7.78-8.22 8-2.44.09-2.82-2.82-8.21-6.09zM62.79 37.75v-.1h.16v.1z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgDecoration2;
