import * as icons from '../icons';

function Icons() {
  const iconClass = 'text-quotables-blue p-2 w-12 h-12';
  return (
    <div className="flex h-full justify-center items-center">
      <div className="flex flex-wrap justify-center items-center w-1/2">
        <icons.CheckMark className={iconClass} />
        <icons.Decoration1 className={iconClass} />
        <icons.Decoration2 className={iconClass} />
        <icons.DecorationTimeline1 className={iconClass} />
        <icons.DecorationTimeline2 className={iconClass} />
        <icons.ExclamationMark className={iconClass} />
        <icons.Logo className={iconClass} />
        <icons.ExpandSign className={iconClass} />
        <icons.Expand1 className={iconClass} />
        <icons.Expand2 className={iconClass} />
        <icons.Expand3 className={iconClass} />
        <icons.Expand4 className={iconClass} />
        <icons.Expand5 className={iconClass} />
        <icons.Close className={iconClass} />
        <icons.Megaphone className={iconClass} />
        <icons.Speaker className={iconClass} />
        <icons.PlusSign className={iconClass} />
        <icons.QuestionMark className={iconClass} />
        <icons.Screen className={iconClass} />
        <icons.Share className={iconClass} />
        <icons.TimelineArrow className={iconClass} />
        <icons.ZoomIn className={iconClass} />
        <icons.ZoomOut className={iconClass} />
        <icons.Wallet className={iconClass} />
        <icons.Twitter className={iconClass} />
        <icons.Discord className={iconClass} />
        <icons.MetaMask className={iconClass} />
        <icons.MetaMaskDisabled
          className={`${iconClass} text-quotables-orange`}
        />
        <icons.Brand className={`${iconClass} w-40`} />
        <icons.EmptyTile className={iconClass} />
        <icons.Mail className={iconClass} />
      </div>
    </div>
  );
}

export default Icons;
