import { useMemo } from 'react';
import reduceCSSCalc from 'reduce-css-calc';
import getStringWidth from './getStringWidth';

function isNumber(val) {
  return typeof val === 'number';
}

function isXOrYInValid(xOrY) {
  return (
    // number that is not NaN or Infinity
    (typeof xOrY === 'number' && Number.isFinite(xOrY)) ||
    // for percentage
    typeof xOrY === 'string'
  );
}

function breakWordOnLines(word, width, style) {
  var chars = word.split('');
  let currentWidth = 0;
  return chars.reduce(
    (lines, ch) => {
      const chWidth = getStringWidth(ch, style) || 0;
      if (lines.length && (currentWidth + chWidth < width)) {
        let curLine = lines[lines.length - 1];
        curLine += ch;
        lines[lines.length - 1] = curLine;
        currentWidth += chWidth;
      } else {
        lines.push(ch);
        currentWidth = chWidth;
      }
      return lines;
    }, []);
}

function breakTextOnLines(text, width, style) {
  const textWidth = getStringWidth(text, style) || 0;
  if (textWidth < width) {
    return [text];
  }

  const spaceWidth = getStringWidth('\u00A0', style) || 0;
  const words = text.split(/(?:(?!\u00A0+)\s+)/);
  let currentWidth = 0;
  return words.reduce(
    (lines, word) => {
      const wordWidth = getStringWidth(word, style) || 0;
      if (lines.length && (currentWidth + wordWidth + spaceWidth < width)) {
        let curLine = lines[lines.length - 1];
        curLine += " " + word;
        lines[lines.length - 1] = curLine;
        currentWidth += wordWidth + spaceWidth;
      } else if (wordWidth < width) {
        lines.push(word);
        currentWidth = wordWidth;
      } else {
        lines = lines.concat(breakWordOnLines(word, width, style));
        const curLine = lines[lines.length - 1];
        currentWidth = getStringWidth(curLine, style);
      }
      return lines;
    }, []);
}

export default function useText(props) {
  const {
    verticalAnchor = 'end',
    scaleToFit = false,
    angle,
    width,
    lineHeight = '1em',
    capHeight = '0.71em', // Magic number from d3
    children,
    style,
    ...textProps
  } = props;

  const { x = 0, y = 0 } = textProps;
  const isXOrYNotValid = !isXOrYInValid(x) || !isXOrYInValid(y);

  const wordsByLines = useMemo(() => {
    if (isXOrYNotValid) {
      return [];
    }

    // Only perform calculations if using features that require them (multiline, scaleToFit)
    if (width || scaleToFit) {
      const text = children == null ? '' : children.toString();
      return breakTextOnLines(text, width, style).map(line => ({
        words: line.split(/\s+/),
      }));
    }

    return [
      {
        words: children == null ? [] : children.toString().split(/(?:(?!\u00A0+)\s+)/),
      },
    ];
  }, [isXOrYNotValid, width, scaleToFit, children, style]);

  const startDy = useMemo(() => {
    const startDyStr = isXOrYNotValid
      ? ''
      : verticalAnchor === 'start'
      ? reduceCSSCalc(`calc(${capHeight})`)
      : verticalAnchor === 'middle'
      ? reduceCSSCalc(
          `calc(${(wordsByLines.length - 1) / 2} * -${lineHeight} + (${capHeight} / 2))`,
        )
      : reduceCSSCalc(`calc(${wordsByLines.length - 1} * -${lineHeight})`);

    return startDyStr;
  }, [isXOrYNotValid, verticalAnchor, capHeight, wordsByLines.length, lineHeight]);

  const transform = useMemo(() => {
    const transforms: string[] = [];
    if (isXOrYNotValid) {
      return '';
    }

    if (isNumber(x) && isNumber(y) && isNumber(width) && scaleToFit && wordsByLines.length > 0) {
      const lineWidth = wordsByLines[0].width || 1;
      const sx = width / lineWidth;
      const sy = sx;
      const originX = x - sx * x;
      const originY = y - sy * y;
      transforms.push(`matrix(${sx}, 0, 0, ${sy}, ${originX}, ${originY})`);
    }
    if (angle) {
      transforms.push(`rotate(${angle}, ${x}, ${y})`);
    }

    return transforms.length > 0 ? transforms.join(' ') : '';
  }, [isXOrYNotValid, x, y, width, scaleToFit, wordsByLines, angle]);

  return { wordsByLines, startDy, transform };
}
