import * as React from 'react';

function SvgScreen(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 213.01 149.09"
      {...props}
    >
      <path
        d="M210.71 114.85a2.39 2.39 0 01-.58 1.72 2.33 2.33 0 01-1.14.57c-1.73.33-65.4.58-85.35.65 4.18 13.56 8.28 23.15 8.86 24.68a4.77 4.77 0 002.88 1.64 10.36 10.36 0 002.72 0c3.78-.12 4.41.15 4.76.57a2.38 2.38 0 01.41 1.73c-24.09.66-46.31.83-70.66.51h-1.17a2.09 2.09 0 01.28-1.83 2.12 2.12 0 011.79-.39s10.08 1.09 12.06-.58a6.63 6.63 0 00.57-.57 5.69 5.69 0 001.15-2.3c.36-1.39 2.83-10.45 6.15-22.92-.8 0-3-.18-5.68-.26-21.4-.63-79.5 1.65-82.56-.35a4.26 4.26 0 01-1.15-1.15 4.1 4.1 0 01-.54-2.56c.26-2.73-.86-95.91-1.46-104.06-.06-.76-.31-3.47 1.33-5.12S7.27 3.47 7.87 3.5c6.25.21 193.1-1.07 198.52-1.46a4.7 4.7 0 013.61.95 4.48 4.48 0 011 3.36c-.14 3.48-.21 47.58-.29 108.5zm-5.74-1.73a1.47 1.47 0 00.57 0c.25-22.2.21-40.62.11-54-.18-22.94-.68-53.27-.68-53.36h-3.45c-2.16 0-192 1.73-192.93 1.72h-1.1l1.73 107.35H94.92c1.66 0 20-.08 30.23-.18 12.16-.11 41.13-.55 79.82-1.53zM89.23 144.37h41.27c-3.49-8.67-5.64-14.6-6.47-18a6.86 6.86 0 00-2.3-4 6.64 6.64 0 00-4-1.15c-6.17-.24-9.39-.36-16.12-.07-2.11.09-3.55.15-5 1.16a8 8 0 00-2.75 4.81c-1.34 5.52-2.95 11.04-4.63 17.25z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M195.85 90.11v8.3c0 3.2-1.71 5-4.79 5.26-5.3.38-10.62.61-15.93.62-1.07 0-2.15-1.24-3.23-1.9 1.09-.68 2.13-1.86 3.27-2 4.57-.37 9.17-.47 13.76-.56 2.28 0 3.21-.93 3.14-3.28-.14-4.45 0-8.9 0-13.36a6.72 6.72 0 01.29-2.83c.36-.71 1.35-1.09 2.07-1.62.43.65 1.21 1.28 1.24 1.94.13 3.12.06 6.26.06 9.39zM41.75 19.53a25.93 25.93 0 01-4.44 1c-3.62.09-7.24 0-10.86-.08-2-.06-2.81.74-2.75 2.76.13 4.45.23 8.91.11 13.36a23.55 23.55 0 01-1.12 4.86l-1.25.05a10.55 10.55 0 01-1.15-2.93c-.47-6-.9-12-1.06-18 0-.95 1.38-2.76 2.12-2.76 6.49 0 13 .29 19.46.53.15 0 .27.35.94 1.21z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgScreen;
