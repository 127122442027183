import * as React from 'react';

function SvgTwitter(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      className="Twitter_svg__socialIcon-32i0_n"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.218 20.298c7.58 0 11.715-6.265 11.715-11.715V8.05A8.897 8.897 0 0022 5.92a7.73 7.73 0 01-2.35.658 4.141 4.141 0 001.818-2.287 8.77 8.77 0 01-2.6 1.003 4.144 4.144 0 00-5.826-.188 4.072 4.072 0 00-1.19 3.915A11.662 11.662 0 013.377 4.73a4.09 4.09 0 001.284 5.481 4.426 4.426 0 01-1.879-.5v.062a4.125 4.125 0 003.289 4.04 4.097 4.097 0 01-1.848.063 4.113 4.113 0 003.853 2.85A8.144 8.144 0 012 18.419a11.142 11.142 0 006.233 1.88"
      />
    </svg>
  );
}

export default SvgTwitter;
