import { useState, useReducer, useContext, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useWeb3React } from '@web3-react/core';
//import { formatEther } from '@ethersproject/units'

import { injected } from '../blockchain';

import TilePreview from './TilePreview';
import BackgroundButton from './BackgroundButton';
import Loading from './Loading';
import MetaMaskActivate from './MetaMaskActivate';
import * as api from '../api';
import { DispatchContext } from '../context';
import { useLocalStorage } from '../hooks';

const getValidators = () => {
  const required = (value) => (value ? undefined : 'Required');
  const maxLength = (len) => (value) =>
    value.length < len ? undefined : `Max ${len} characters`;
  const onlyPrintable = (value) =>
    /^[\x20-\x7f]+$/.test(value) ? undefined : 'Only ASCII characters';
  //const noLeadingSpaces = value => /^\s/.test(value) ? 'No leading spaces' : undefined;
  //const noTrailingSpaces = value => /\s$/.test(value) ? 'No trailing spaces' : undefined;
  const noMultipleSpaces = (value) =>
    /\s{2}/.test(value) ? 'No multiple spaces' : undefined;
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined,
      );
  return composeValidators(
    required,
    maxLength(128),
    onlyPrintable,
    //noLeadingSpaces,
    //noTrailingSpaces,
    noMultipleSpaces,
  );
};

function reducer(state, action) {
  switch (action.type) {
    case 'client-succeed': {
      if (state.value !== 'create-pending') {
        return { value: 'client-succeed' };
      }
      return state;
    }
    case 'client-failed': {
      if (state.value !== 'create-pending') {
        return { value: 'client-failed', error: action.error };
      }
      return state;
    }
    case 'server-pending': {
      if (state.value === 'client-succeed') {
        return { value: 'server-pending' };
      }
      return state;
    }
    case 'server-succeed': {
      if (state.value === 'server-pending') {
        return { value: 'server-succeed' };
      }
      return state;
    }
    case 'server-failed': {
      if (state.value === 'server-pending') {
        return { value: 'server-failed', error: action.error };
      }
      return state;
    }
    case 'contact-info': {
      if (state.value === 'server-succeed') {
        return { value: 'contact-info' };
      }
      return state;
    }
    case 'create-pending': {
      if (state.value === 'contact-info') {
        return { value: 'create-pending' };
      }
      return state;
    }
    case 'create-succeed': {
      if (state.value === 'create-pending') {
        return { value: 'create-succeed' };
      }
      return state;
    }
    case 'create-failed': {
      if (state.value === 'create-pending') {
        return { value: 'create-failed', error: action.error };
      }
      return state;
    }
    default: {
      throw new Error();
    }
  }
}

const validators = getValidators();

function TextField({ title, value, onChange, tabIndex = -1 }) {
  return (
    <div className="text-black focus-within:text-black border border-primary-gray m-1">
      <input
        type="text"
        className="w-full p-2 bg-quotables-light-blue focus:outline-none placeholder-quotables-light-gray"
        placeholder={title}
        value={value}
        onChange={onChange}
        tabIndex={tabIndex}
        autoFocus
      />
    </div>
  );
}

const emailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const emailField = value => emailRE.test(value) ? undefined : 'Not valid email';
const requiredField = value => value ? undefined : 'Required';
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

function useValidateContacts() {
  const [state, setState] = useState('initial');
  const callback = contacts => {
    setState('loading');
    api.validateContacts(contacts)
      .then(r => setState('ready'))
      .catch(err => setState('error'));
  };
  return [state, callback];
}

function Contacts({ onHide }) {
  const [contacts, setContacts] = useLocalStorage('contacts', {
    email: '',
    firstname: '',
    lastname: '',
    country: '',
    city: '',
    zipcode: '',
    address: '',
  });
  const valid = (
    composeValidators(requiredField, emailField)(contacts.email) === undefined &&
    requiredField(contacts.firstname) === undefined &&
    requiredField(contacts.lastname) === undefined &&
    requiredField(contacts.country) === undefined &&
    requiredField(contacts.city) === undefined &&
    requiredField(contacts.zipcode) === undefined &&
    requiredField(contacts.address) === undefined
  );
  const [state, onSubmit] = useValidateContacts();
  useEffect(() => {
    if (state === 'ready') {
      onHide();
    }
  }, [state, onHide]);
  return (
    <div className="flex flex-col items-stretch w-full justify-between text-center">
      <h2 className="text-sm">Please provide your billind info</h2>
      <TextField
        title="Email"
        value={contacts.email}
        onChange={(e) =>
          setContacts((value) => ({ ...value, email: e.target.value }))
        }
        tabIndex={1}
      />
      <div className="flex flex-row">
        <TextField
          title="Firstname"
          value={contacts.firstname}
          onChange={(e) =>
            setContacts((value) => ({ ...value, firstname: e.target.value }))
          }
          tabIndex={2}
        />
        <TextField
          title="Lastname"
          value={contacts.lastname}
          onChange={(e) =>
            setContacts((value) => ({ ...value, lastname: e.target.value }))
          }
          tabIndex={3}
        />
      </div>
      <div className="flex flex-row">
        <TextField
          title="Country"
          value={contacts.country}
          onChange={(e) =>
            setContacts((value) => ({ ...value, country: e.target.value }))
          }
          tabIndex={4}
        />
        <TextField
          title="Zip"
          value={contacts.zipcode}
          onChange={(e) =>
            setContacts((value) => ({ ...value, zipcode: e.target.value }))
          }
          tabIndex={5}
        />
      </div>
      <TextField
        title="City"
        value={contacts.city}
        onChange={(e) =>
          setContacts((value) => ({ ...value, city: e.target.value }))
        }
        tabIndex={6}
      />
      <TextField
        title="Address"
        value={contacts.address}
        onChange={(e) =>
          setContacts((value) => ({ ...value, address: e.target.value }))
        }
        tabIndex={7}
      />
      <button
        className="text-white bg-quotables-orange font-semibold py-2 px-4 mr-2 uppercase disabled:opacity-50"
        onClick={() => onSubmit(contacts)}
        disabled={!valid || state === 'loading'}
        tabIndex={8}
      >
        Buy Quotable
      </button>
    </div>
  );
}

function NewTileBlockchain() {
  const context = useWeb3React();
  const { library, account, activate, active, error } = context;

  const [text, setText] = useState('');
  const [bg, setBg] = useState(1);
  const [stage, setStage] = useReducer(reducer, { value: 'initial' });

  const serverSideValidation = useDebouncedCallback((txt) => {
    setStage({ type: 'server-pending' });
    api
      .validateText(txt)
      .then(() => setStage({ type: 'server-succeed' }))
      .catch((err) => setStage({ type: 'server-failed', error: err.message }));
  }, 1500);

  const onChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\x20-\x7f]/g, '');
    const err = validators(value);
    if (err === undefined) {
      setStage({ type: 'client-succeed' });
      serverSideValidation(value);
    } else {
      setStage({ type: 'client-failed', error: err });
    }
    setText(value);
  };

  const onSubmit = () => {
    setStage({ type: 'create-pending' });
    api
      .mintToken(library, account, text, bg)
      .then((tileId) => api.getTile(tileId))
      .then((tile) => setStage({ type: 'create-succeed' }))
      .catch((err) => {
        console.log(err);
        setStage({
          type: 'create-failed',
          error: err.message || 'Unknown error',
        });
      });
  };

  const onTryConnect = () => activate(injected);

  if (stage.value === 'contact-info') {
    return <Contacts onHide={onSubmit} />;
  }

  return (
    <BaseComponent
      text={text}
      bg={bg}
      setBg={setBg}
      stage={stage}
      active={active}
      error={error}
      onSubmit={() => setStage({ type: 'contact-info' })}
      onChange={onChange}
      onTryConnect={onTryConnect}
    />
  );
}

function useSaveContacts() {
  const [state, setState] = useState('initial');
  const [contacts] = useLocalStorage('contacts', {
    email: '',
    firstname: '',
    lastname: '',
    country: '',
    city: '',
    zipcode: '',
    address: '',
  });
  const callback = () => {
    setState('loading');
    api.addContacts(contacts)
      .then(r => setState('ready'))
      .catch(err => setState('error'));
  };
  return [state, callback];
}

const BuySucceed = () => {
  const { showBuySucceed } = useContext(DispatchContext);
  const [state, saveContacts] = useSaveContacts();
  useEffect(() => {
    if (state === 'initial') {
      saveContacts();
    } else if (state !== 'loading') {
      showBuySucceed();
    }
  }, [showBuySucceed, state, saveContacts]);
  return <Loading />;
};

const BaseComponent = ({
  text,
  bg,
  stage,
  error,
  setBg,
  active,
  onSubmit,
  onChange,
  onTryConnect,
}) => (
  <div className="flex flex-col justify-center items-center my-0 mx-auto">
    {stage.value === 'create-pending' && <Loading />}
    {stage.value === 'create-succeed' && <BuySucceed />}
    {!active && <MetaMaskActivate onTryConnect={onTryConnect} error={error} />}
    <div className="flex flex-row text-xs text-quotables-gray">
      <div className="flex justify-center items-center">
        <TilePreview size={400} bg={bg}>
          {text}
        </TilePreview>
      </div>
      <div className="flex flex-col justify-around ml-4">
        <span className="">Type your quote here:</span>
        <textarea
          className="w-full border-quotables-gray border rounded p-2"
          value={text}
          placeholder="..."
          onChange={onChange}
          disabled={stage.value === 'create-pending'}
        />
        {stage.error && <span className="text-red-500">{stage.error}</span>}
        <span>Choose your tile design:</span>
        <div className="w-full flex flex-row justify-between">
          <BackgroundButton size={64} bg={1} onClick={() => setBg(1)} />
          <BackgroundButton size={64} bg={2} onClick={() => setBg(2)} />
          <BackgroundButton size={64} bg={3} onClick={() => setBg(3)} />
          <BackgroundButton size={64} bg={4} onClick={() => setBg(4)} />
          <BackgroundButton size={64} bg={5} onClick={() => setBg(5)} />
        </div>
        <div>
          Price: <span className="font-semibold">0.01 ETH</span>
        </div>
        <div>
          <button
            className="text-white bg-quotables-orange font-semibold py-2 px-4 mr-2 uppercase disabled:opacity-50"
            onClick={onSubmit}
            disabled={!active || stage.value !== 'server-succeed'}
          >
            Buy Quotable
          </button>
        </div>
      </div>
    </div>
  </div>
);

function NewTileDebug() {
  const [text, setText] = useState('');
  const [bg, setBg] = useState(1);
  const [stage, setStage] = useReducer(reducer, { value: 'initial' });

  const serverSideValidation = useDebouncedCallback((txt) => {
    setStage({ type: 'server-pending' });
    api
      .validateText(txt)
      .then(() => setStage({ type: 'server-succeed' }))
      .catch((err) => setStage({ type: 'server-failed', error: err.message }));
  }, 1500);

  const onChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\x20-\x7f]/g, '');
    const err = validators(value);
    if (err === undefined) {
      setStage({ type: 'client-succeed' });
      serverSideValidation(value);
    } else {
      setStage({ type: 'client-failed', error: err });
    }
    setText(value);
  };

  const onSubmit = () => {
    setStage({ type: 'create-pending' });
    api
      .createTileDebug(text, bg)
      .then(() => setStage({ type: 'create-succeed' }))
      .catch((err) => setStage({ type: 'create-failed', error: err.message }));
  };

  return (
    <BaseComponent
      text={text}
      bg={bg}
      setBg={setBg}
      stage={stage}
      active={true}
      onSubmit={onSubmit}
      onChange={onChange}
      onTryConnect={() => {}}
    />
  );
}

const SERVER_TYPE = process.env.REACT_APP_SERVER_TYPE || 'DEBUG';
export default SERVER_TYPE === 'DEBUG' ? NewTileDebug : NewTileBlockchain;
