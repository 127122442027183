import { memo, useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected, getErrorMessage } from '../blockchain';
import * as icons from '../icons';
import { DispatchContext } from '../context';
import { Widget } from './Base';

function MetaMaskWidget({ item: { width, height, rem }, dragRef, inline }) {
  const context = useWeb3React();
  const { showAlert } = useContext(DispatchContext);
  const { active, activate } = context;
  const onTryConnect = () => {
    activate(injected, undefined, true).catch((error) => {
      showAlert(getErrorMessage(error));
    });
  };
  return (
    <Widget width={width} height={height} inline={inline}>
      <div
        className="cursor-move h-full py-2 text-quotables-blue bg-white flex flex-row justify-center items-center"
        ref={dragRef}
        style={{ padding: `${0.5 * rem}px 0` }}
      >
        {active ? (
          <button>
            <icons.MetaMask
              className="h-8 w-8 cursor-pointer focus:outline-none"
              style={{ width: `${2 * rem}px`, height: `${2 * rem}px` }}
            />
          </button>
        ) : (
          <button
            className="h-8 w-8 cursor-pointer focus:outline-none"
            style={{ width: `${2 * rem}px`, height: `${2 * rem}px` }}
            onClick={onTryConnect}
          >
            <icons.MetaMaskDisabled />
          </button>
        )}
      </div>
    </Widget>
  );
}

export default memo(MetaMaskWidget);
