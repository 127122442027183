import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected } from '../blockchain';
import { getOwnedTokens, getTileList } from '../api';
import MetaMaskActivate from './MetaMaskActivate';
import Loading from './Loading';
import TilePreview from './TilePreview';

const TileOwned = ({ className, size = 150 }) => {
  const context = useWeb3React();
  const { library, account, active, activate, error } = context;

  const [stage, setStage] = React.useState('initial');
  const [tiles, setTiles] = React.useState([]);

  const onTryConnect = () => activate(injected);

  React.useEffect(() => {
    const onLoad = () => {
      setStage('pending');
      getOwnedTokens(library, account)
        .then((data) => getTileList(data))
        .then((data) => setTiles(data))
        .then(() => setStage('succeed'))
        .catch(() => setStage('failed'));
    };
    if (active && stage === 'initial') {
      onLoad();
    }
  }, [active, stage, setTiles, account, library]);

  if (!active) {
    return <MetaMaskActivate onTryConnect={onTryConnect} error={error} />;
  }

  return (
    <ul
      className={`flex flex-row flex-wrap justify-center ${
        className ? className : ''
      }`}
    >
      {stage === 'succeed' &&
        tiles.map((it, idx) => (
          <li className="m-2" key={idx}>
            <TilePreview size={size} text={it.title} bg={it.bg} />
          </li>
        ))}
      {stage === 'succeed' && tiles.length === 0 && <li>No data</li>}
      {stage === 'failed' && <li>Error</li>}
      {stage === 'pending' && <Loading />}
    </ul>
  );
};

export default TileOwned;
