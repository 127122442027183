import React, { useState, useEffect } from 'react';
import { TimelineArrow } from '../icons';

const calculateTimeLeft = () => {
  const year = new Date().getFullYear();
  const difference = +new Date(`11/01/${year}`) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

function Countdown({ className }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });
  const timerComponents = [];
  Object.keys(timeLeft).forEach((interval, i) => {
    timerComponents.push(
      <React.Fragment key={i}>
        <span className="mx-2 text-6xl leading-none w-24 text-right">
          {timeLeft[interval]}
        </span>
        <span className="text-base">{interval}</span>
      </React.Fragment>,
    );
  });
  return (
    <div className={`flex flex-col p-4 pr-12 items-center ${className}`}>
      <div className="flex flex-row">
        <TimelineArrow className="h-12 w-6" />
        <h2 className="pl-2 text-xl font-semibold">
          Quotables Sale Starting Soon
        </h2>
      </div>
      <div className="flex flex-row items-end font-semibold text-quotables-orange uppercase">
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </div>
    </div>
  );
}

export default Countdown;
