import { useContext, memo } from 'react';
import { DispatchContext } from '../context';
import * as icons from '../icons';
import { Widget, BlueWindow, ExpandButton } from './Base';

function Block({ icon: Icon, text, rem }) {
  return (
    <div
      className="flex flex-row items-center p-2"
      style={{ padding: `${0.5 * rem}px` }}
    >
      <Icon
        className="h-6 w-6"
        style={{ width: `${1.5 * rem}px`, height: `${1.5 * rem}px` }}
      />
      <h2
        className="ml-2"
        style={{ marginLeft: `${0.5 * rem}px`, fontSize: `${1.0 * rem}px` }}
      >
        {text}
      </h2>
    </div>
  );
}

function FaqWidget({ item: { width, height, rem }, dragRef, inline }) {
  const { showFaqModal } = useContext(DispatchContext);
  return (
    <Widget width={width} height={height} inline={inline}>
      <BlueWindow title="FAQ" dragRef={dragRef} rem={rem}>
        <div
          className="h-full flex flex-col p-2 pl-4 text-quotables-blue font-semibold justify-around cursor-pointer"
          onClick={showFaqModal}
          style={{
            padding: `${0.5 * rem}px ${0.5 * rem}px ${0.5 * rem}px ${
              1.0 * rem
            }px`,
          }}
        >
          <Block icon={icons.QuestionMark} text="How It Works" rem={rem} />
          <Block
            icon={icons.ExclamationMark}
            text="Rules for the Content"
            rem={rem}
          />
          <Block
            icon={icons.CheckMark}
            text="Verified Smart Contract"
            rem={rem}
          />
          <div className="flex flex-row items-center justify-end">
            <ExpandButton
              className="bg-quotables-blue text-white"
              onClick={showFaqModal}
              rem={rem}
            />
          </div>
        </div>
      </BlueWindow>
    </Widget>
  );
}

export default memo(FaqWidget);
