import React from 'react';
import { animated, to } from 'react-spring';
import { useSpring, config } from 'react-spring';
import { useWheel, useDrag } from 'react-use-gesture';
import * as icons from '../icons';
import { useDoubleClick } from '../hooks';

export const clamp = (value, min, max) => Math.max(Math.min(value, max), min);

export const transform = ({ xy, scale }) =>
  to(
    [xy, scale],
    ([x, y], scale) => `translate(${x}px, ${y}px) scale(${scale})`,
  );

const roundZeroDp = (value) => Math.floor(value).toFixed(0);

const getScaleRate = (scale) => {
  if (scale < 1) return 0.0015;
  if (scale < 2) return 0.002;
  return 0.004;
};

const Zoomable = ({ children, width, height, maxZoom = 8, minZoom = 0.2, initialZoom = 0.2, initialX = 0, initialY = 0 }) => {
  const containerRef = React.useRef();
  const [spring, springApi] = useSpring(() => ({
    xy: [initialX, initialY],
    scale: initialZoom,
    config: config.slow,
  }));

  const getContainerBoundingClientRect = () =>
    containerRef.current.getBoundingClientRect();
  const getFocalPoint = () => {
    const rect = getContainerBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;
    return { x, y };
  };
  const limitScale = (scale) => clamp(scale, minZoom, maxZoom);
  const limitTranslation = ([x, y], scale) => {
    const bound = getContainerBoundingClientRect();
    const w = width * scale - bound.width;
    const h = height * scale - bound.height;
    return [clamp(x, -w, 0), clamp(y, -h, 0)];
  };

  const scaleFromPoint = (newScale, fromPoint, toPoint) => {
    // we have a rectangle area in the window coord system, which is
    // defined by its origin point and some affine transformation
    // (it's scaled and translated).
    //
    // we click somewhere in that area and want to zoom into the clicked
    // point. Technically we have p0 point in the local coordinate system
    // which is translated by the initial transformation to the point fromPoint
    // in the window coordinate system. We need to find out new transformation
    // which would transform point p0 to toPoint.
    //
    // fromPoint and toPoint are in the window coordinate system
    // local0 and local1 are the same points in the local coordinate system
    //
    // local0 = fromPoint - origin
    // local1 = toPoint - origin
    //
    // and the equations for points are:
    //
    // local0 = p0 * s0 + tr0
    // local1 = p0 * s1 + tr1
    //
    // we know local1, local2, s0, s1, tr0
    // need to find tr1
    //
    // p0 = (local0 - tr0) / s0
    //
    // local1 - local0 = p0 * (s1 - s0) + (tr1 - tr0)
    // tr1 = local1 - ratio * (local0 - tr0)
    //
    const { tr0, s } = to([spring.xy, spring.scale], ([x, y], s) => ({
      tr0: { x, y },
      s,
    })).get();
    const ratio = newScale / (s !== 0 ? s : 1);

    const bound = getContainerBoundingClientRect();
    const origin = {
      x: bound.left,
      y: bound.top,
    };
    const local0 = {
      x: fromPoint.x - origin.x,
      y: fromPoint.y - origin.y,
    };
    const local1 = {
      x: toPoint.x - origin.x,
      y: toPoint.y - origin.y,
    };
    const tr1 = {
      x: local1.x - (local0.x - tr0.x) * ratio,
      y: local1.y - (local0.y - tr0.y) * ratio,
    };
    springApi.start({
      xy: limitTranslation([tr1.x, tr1.y], newScale),
      scale: newScale,
    });
  };

  const changeScale = (delta) => {
    const { scale } = spring;

    const targetScale = scale.get() + delta;
    const newScale = limitScale(targetScale);

    const focal = getFocalPoint();
    scaleFromPoint(newScale, focal, focal);
  };

  const pan = useDrag(
    ({ down, movement: [x, y] }) => {
      if (down) {
        springApi.start({
          xy: limitTranslation([x, y], spring.scale.get()),
        });
      }
    },
    {
      delay: 400,
      initial: () => spring.xy.get(),
    },
  );

  const zoom = useWheel(({ delta: [, d], event: e }) => {
    const scale = spring.scale.get();
    const mousePos = { x: e.clientX, y: e.clientY };
    const scaleChange = 2 ** (d * getScaleRate(scale));
    const targetScale = scale + 1 - scaleChange;
    const newScale = limitScale(targetScale);
    scaleFromPoint(newScale, mousePos, mousePos);
  });

  useDoubleClick({
    ref: containerRef,
    onDoubleClick: (e) => {
      const scale = spring.scale.get();
      const newScale = limitScale(scale > minZoom + 1 ? minZoom : maxZoom);
      const mousePos = { x: e.clientX, y: e.clientY };
      const focal = getFocalPoint();
      scaleFromPoint(newScale, mousePos, focal);
    },
  });

  const panzoom = () => ({ ...pan(), ...zoom() });
  const bind = panzoom();

  return (
    <div className="w-full h-full flex flex-col">
      <div
        className="flex-grow overflow-hidden relative"
        ref={containerRef}
        {...bind}
        style={{ touchAction: 'none' }}
      >
        <animated.div
          id="dat"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            transform: transform(spring),
            transformOrigin: 'left top',
          }}
        >
          {children}
        </animated.div>
      </div>
      <Controls
        scale={spring.scale}
        setScale={changeScale}
        step={Math.abs(maxZoom - minZoom) / 10}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />
    </div>
  );
};

const Controls = ({ scale, setScale, step, minZoom, maxZoom }) => {
  const ref = React.useRef();
  const bind = useDrag(
    ({ down, xy: [x] }) => {
      const bound = ref.current.getBoundingClientRect();
      const percent = (x - bound.left) / bound.width;
      const newScale = minZoom + percent * (maxZoom - minZoom);
      const diff = newScale - scale.get();
      setScale(diff);
    },
    { axis: 'x' },
  );
  const zoomOutHandler = (e) => {
    e.stopPropagation();
    setScale(-step);
  };
  const zoomInHandler = (e) => {
    e.stopPropagation();
    setScale(step);
  };
  const zoomPanelHandler = (e) => {
    e.stopPropagation();
    const bound = ref.current.getBoundingClientRect();
    const x = e.clientX;
    const percent = (x - bound.left) / bound.width;
    const newScale = minZoom + percent * (maxZoom - minZoom);
    const diff = newScale - scale.get();
    setScale(diff);
  };
  const zoomPercent = to(
    [scale],
    (s) => `${roundZeroDp(((s - minZoom) / (maxZoom - minZoom)) * 100)}%`,
  );
  return (
    <div className="p-2 flex flex-row justify-end px-4 bg-quotables-blue bg-opacity-50 items-center rounded">
      <icons.ZoomOut className="h-6 w-6 text-white" onClick={zoomOutHandler} />
      <div
        className="h-1 w-40 mx-4 bg-white overflow-visible rounded relative"
        onClick={zoomPanelHandler}
        ref={ref}
      >
        <animated.div
          className="absolute h-4 w-4 rounded-full bg-quotables-orange transform -translate-x-1/2 -translate-y-1/2 top-1/2"
          style={{ left: zoomPercent }}
          {...bind()}
        />
      </div>
      <icons.ZoomIn className="h-6 w-6 text-white" onClick={zoomInHandler} />
    </div>
  );
};

export default Zoomable;
