import * as icons from '../icons';

export function Header({ decoClassName, children }) {
  return (
    <>
      <icons.Decoration1 className={`h-6 w-6 ${decoClassName}`} />
      <h1 className="font-semibold text-xl text-center">{children}</h1>
      <icons.Decoration1
        className={`h-6 w-6 transform rotate-180 ${decoClassName}`}
      />
    </>
  );
}

export function ExpandButton({ className }) {
  return (
    <span className={`p-2 rounded-full cursor-pointer ${className}`}>
      <icons.Expand4 className="h-4 w-4" />
    </span>
  );
}
