import { defaultAbiCoder } from '@ethersproject/abi';
import { BigNumber } from '@ethersproject/bignumber';
import { hexConcat } from '@ethersproject/bytes';

const CONTRACT_ADDR = process.env.REACT_APP_CONTRACT_ADDR || '';

export async function mintToken(library, account, text, bg) {
  const params = defaultAbiCoder.encode(['string', 'uint8'], [text, bg]);
  const calldata = hexConcat(['0xcba5c964', params]);

  const price = await getPrice(library);

  const tx = {
    to: CONTRACT_ADDR,
    data: calldata,
    value: BigNumber.from(price).toHexString(),
  };
  const signer = library.getSigner(account);
  const txresp = await signer.sendTransaction(tx);
  const receipt = await txresp.wait();
  const log = receipt.logs[0];
  const topic = log.topics[3];
  return BigNumber.from(topic).toString();
}

export async function getPrice(provider) {
  const result = await provider.send('eth_call', [
    {
      to: CONTRACT_ADDR,
      data: '0xfb107a4f',
    },
    'latest',
  ]);
  return BigNumber.from(result).toString();
}

const range = (start, end) => {
  return Array.from({ length: end - start }, (_, i) => start + i);
};

async function getOwnedTokenIndex(provider, owner, index) {
  const params = defaultAbiCoder.encode(['address', 'uint256'], [owner, index]);
  const calldata = hexConcat(['0x2f745c59', params]);
  const result = await provider.send('eth_call', [
    {
      to: CONTRACT_ADDR,
      data: calldata,
    },
    'latest',
  ]);
  return BigNumber.from(result).toNumber();
}

async function getOwnedTokensCount(provider, owner) {
  const params = defaultAbiCoder.encode(['address'], [owner]);
  const calldata = hexConcat(['0x70a08231', params]);
  const result = await provider.send('eth_call', [
    {
      to: CONTRACT_ADDR,
      data: calldata,
    },
    'latest',
  ]);
  return BigNumber.from(result).toNumber();
}

export async function getOwnedTokens(provider, owner) {
  const count = await getOwnedTokensCount(provider, owner);
  const requests = range(0, count).map((index) =>
    getOwnedTokenIndex(provider, owner, index),
  );
  return await Promise.all(requests);
}
