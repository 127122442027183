import * as React from 'react';

function SvgPlusSign(props) {
  return (
    <svg
      id="PlusSign_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 171.69 165.76"
      {...props}
    >
      <defs>
        <style>{'.PlusSign_svg__cls-2{fill:#fff}'}</style>
      </defs>
      <path
        d="M66.87 100.39a6.39 6.39 0 00-.9-3.6c-2.55-4-9.86-3.48-18.89-2.69-18.16 1.57-31.84 2.77-40.47-1.8a9.53 9.53 0 01-4.5-4.5c-1.5-3.54-.09-8.18 2.7-10.79 1.36-1.28 2.83-1.79 5.7-2.75a42 42 0 019-2c7.84-.73 32-1.55 39-3a7.07 7.07 0 006-6c2.54-11.53 1.14-25.53 1.14-25.53h0c-.78-7.41-1-9.51-1.14-13.47-.25-11.12 1.4-14 2-15 3.71-6 10.59-7.33 11.56-7.51.65-.12 8.89-1.48 14.39 3.6 6.43 5.93 5.64 17.39 5 25.91-.1 1.48-.16 2.77-.19 4 0 1.68-.07 4.55.19 9 .31 5.31.47 8 1 10a27.31 27.31 0 003.7 8.16 9.45 9.45 0 004.3 2.84c5.25 1.7 37.13-.46 51.51 1.85 2 .33 3.63.69 4.5.9a13.72 13.72 0 016.29 6.3c.6 1.24 2.17 4.56.9 8.09a9.79 9.79 0 01-3.59 4.5c-4 3-8.54 3.4-12.6 3.6-8.21.41-13.91.07-21.58 0-11-.11-24.91.45-26.39 1.8-3.74 3.38-2.85 9.67-2.16 15.9 2.91 25.88 4.56 42.26-3.24 50.65-.68.73-4.83 5.19-10.79 5.4-4.9.17-8.61-2.61-9.9-3.6-4.92-3.79-8.87-10.76-11.69-46.77-.39-5.64-.67-10.32-.85-13.49zm97.45-19.79c1.18-2.18.54-5.23-.9-7.19-2-2.75-5.55-3.28-9-3.6-7.87-.72-14.52-.29-22.91.45 0 0-22.09.89-31.36-1.35a6.06 6.06 0 01-1.64-.65c-2.63-1.55-3.35-4.86-3.69-6.56a160.35 160.35 0 01-3-22.18c-1.09-11.6-1-9.49-1.16-11.69-1.26-15.78-3.06-17.78-4.52-18.88-2.93-2.21-7.69-2.87-10.79-.9-3.51 2.22-3.67 9.16-3.6 23.38 0 7.16.41 19.42-.26 25.83-.56 5.46-1.34 5.05-1.94 10.75a5.63 5.63 0 01-.69 2.47c-1.68 2.84-6 3.17-7.83 3.35-12.37 1.19-32.46 2.17-34.65 2.28-13 .65-16.09 2.17-17.09 4.49s0 5.08 1.45 6.81c2.61 3.19 7.19 3.19 12.94 3.09 15.09-.28 22.63-.42 27.88-.9 9.47-.87 14.83-1.64 18 1.8 1.2 1.3 1.78 2.89 2.7 5.39a35 35 0 012.09 11.2c.22 9.21.53 21.18 2.1 31.14 2 12.84 5 14.92 6.28 15.64a11.12 11.12 0 0012-1.31c2.21-1.93 3.08-4.68 3.6-10.8a99.36 99.36 0 00-.4-19.52 175.14 175.14 0 01-.5-29.94 9.25 9.25 0 011.8-5.4c2.65-3.23 19.22-2.88 29.09-2.7 0 0 9.07.17 24.28-.9 6.74-.47 10.3-.98 11.72-3.6z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
    </svg>
  );
}

export default SvgPlusSign;
