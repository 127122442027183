import * as React from 'react';

function SvgQuestionMark(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 205.08 196.11"
      {...props}
    >
      <path
        d="M21.57 38.15c-1.38 2.34-2.79 4.66-4.14 7C6.05 64.86 2.12 86.03 4.78 108.56c1.38 11.69 3.75 23.1 8.79 33.8a83.22 83.22 0 0012.26 18.96 85 85 0 0026.39 20.4c28.22 14.4 57.61 8.95 63.74 7.69 36.33-7.47 59.73-33.32 69.16-50.71a100.11 100.11 0 0011.73-43h0c-.54-4.05-.43-8.18-1-12.21a71.25 71.25 0 00-5.24-18.54c-5.88-13.31-14-25-25.44-34.13a100.58 100.58 0 00-59.19-21.47 73.93 73.93 0 00-13.35 1c-15.44 2.16-28.91 9.34-42.14 17-3.53 2.05-6.94 4.29-10.7 6.16 2.06-2.15 4-4.44 6.21-6.42 8.94-8 18.44-15.3 29.88-19.37a118.86 118.86 0 0117.51-4.65 99.21 99.21 0 0115.6-1.53 85.17 85.17 0 0137.03 7.61c13.13 5.76 25 13.42 34.51 24.34a97.22 97.22 0 0119.4 33.95c4 12.36 4.16 25 3 37.71a85.43 85.43 0 01-6.81 27.24c-7.74 17.51-18.84 32.5-34.48 43.79a96.81 96.81 0 01-30.66 14.69 107.61 107.61 0 01-33.91 3.68c-5-.2-10-.36-14.9-1-23.72-3.21-44.22-12.7-59.81-31.42a83.76 83.76 0 01-16.8-33.47 121.71 121.71 0 01-4-36.08A95.13 95.13 0 0114.28 48.4c1.1-1.89 2.34-6 6.11-9.28.51-.46.91-.77 1.18-.97z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        d="M135.91 67.24a41.43 41.43 0 01-3.1 15.8 43.27 43.27 0 01-8.55 13.34c-6.76 7.15-9 8.61-11.77 11.52a57.42 57.42 0 00-4.36 5.13s-3.38 4.33-7 9.8c0 0-6 8.91-8.28 16.64a6.84 6.84 0 00-.29 1.64v1h0a58.16 58.16 0 00-9-.37h-.66v-.43c-.19-3.36-1.28-25.52 4.49-35a37.74 37.74 0 012.65-3.73 42.34 42.34 0 017.53-6.77c3.92-2.93 8-5.77 11.42-9.25 3.71-3.81 5.14-9 5.34-14.24s-5.82-10.74-11.51-11.27c-6.82-.64-12.85 1.7-18.61 4.91-3.62 2-7.11 4.26-10.63 6.43a5.17 5.17 0 01-5.3.12c-4.85-2.43-8.93-5.94-12.83-9.65-1.45-1.38-1.35-2.07.25-3.42 4.25-3.58 8.34-7.38 12.84-10.61a51.06 51.06 0 0119.05-8.19 57.79 57.79 0 0126.8.2 28.73 28.73 0 0114.68 8.41 25.85 25.85 0 016.1 11.06 26.47 26.47 0 01.74 6.93zm-51.43 73.77h6.87v-.76c0-6.06 7.68-18.45 7.68-18.45a73.46 73.46 0 016.91-10.2c1.19-1.45 1.85-2.13 7.69-8.24 8-8.31 8.27-8.64 9-9.53 2.62-3.23 5.66-6.89 7.73-12.25a30.69 30.69 0 002.5-14.81c-.91-8.79-6.32-17.74-14.61-21.8-2.62-1.28-4.64-1.63-8.45-2.3a58.3 58.3 0 00-24.9.9 55.11 55.11 0 00-15.07 6.77c-6.38 4-4.53 4.64-9.66 7.63-.94.55-2.71 1.52-2.72 2.86 0 .72.86 1.44 2.61 2.82a103.8 103.8 0 009.19 6.52 3.87 3.87 0 003.37 0c4.06-2.4 8-5.09 11.93-7.62 6.88-4.36 14.33-6 22.18-3.6 6.18 1.87 12.21 7.54 10.52 16.24-1.4 7.25-5.3 12.92-11 17.45-2.09 1.66-2.45 1.79-4.73 3.56-3.22 2.5-9.08 7-12.48 13.28-2 3.69-2.52 7.87-3.54 16.23a140.2 140.2 0 00-1.02 15.3zM92.89 160.98a7.34 7.34 0 00-5.56-1.31 8.6 8.6 0 00-3.52 1.21l-.14.1a4.24 4.24 0 011.54-2.31 5.24 5.24 0 013-.77 11 11 0 00-2.2.15c-3.27.67-7.88 4.73-7 9.84a8.36 8.36 0 007.68 6.92c4.65.18 8.1-3.91 8.45-7.69.09-.39.37-3.97-2.25-6.14zm-1.54 8.45a5.83 5.83 0 01-4.86 2.3 4.72 4.72 0 01-3.59-1.53 4.55 4.55 0 01-.77-3.84 5.32 5.32 0 014.61-3.85c2.16-.21 4.72.92 5.38 3.08a4.67 4.67 0 01-.77 3.84z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
    </svg>
  );
}

export default SvgQuestionMark;
