import * as React from 'react';

function SvgExpand5(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76.49 75.29"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={2}
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M15.5 74.29H7c-3.27 0-5.15-1.85-5.37-5.21C1.25 63.31 1 57.52 1 51.73c0-1.17 1.27-2.34 2-3.51.69 1.18 1.9 2.32 2 3.55.39 5 .49 10 .57 15 0 2.49 1 3.5 3.36 3.42 4.54-.15 9.1-.05 13.65 0a6.44 6.44 0 012.9.32c.72.39 1.11 1.47 1.65 2.25-.66.47-1.3 1.32-2 1.35-3.19.14-6.4.06-9.59.06zM24 2.89a24.5 24.5 0 01-4.54 1c-3.7.11-7.41 0-11.11-.08-2.07-.07-2.87.81-2.81 3 .14 4.85.24 9.7.12 14.55a27.64 27.64 0 01-1.12 5.34l-1.29.05a12.37 12.37 0 01-1.17-3.18C1.6 17.05 1.16 10.53 1 4c0-1 1.41-3 2.17-3 6.62 0 13.25.31 19.88.57.15.01.29.43.95 1.32zM59.1 1h8.49c3.27 0 5.15 1.86 5.41 5.21.38 5.78.62 11.57.64 17.35 0 1.17-1.27 2.35-1.95 3.52-.7-1.18-1.9-2.32-2-3.56-.38-5-.48-10-.57-15 0-2.49-1-3.5-3.35-3.42-4.55.14-9.11 0-13.66 0a6.46 6.46 0 01-2.9-.31c-.72-.39-1.11-1.47-1.64-2.25.65-.47 1.3-1.32 2-1.35 3.2-.15 6.4-.07 9.6-.07zM52.48 71.39a24.32 24.32 0 014.54-1c3.69-.11 7.4 0 11.1.08 2.07.07 2.87-.81 2.81-3-.14-4.85-.23-9.7-.12-14.55A28.45 28.45 0 0172 47.58h1.28a12 12 0 011.18 3.18c.47 6.52.91 13 1.07 19.57 0 1-1.4 3-2.16 3-6.63 0-13.26-.31-19.88-.57-.2-.06-.34-.43-1.01-1.37z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgExpand5;
