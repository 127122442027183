import * as React from 'react';

function SvgExpand3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 82.67 64.71"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M14.18 6.21C25.88 13.84 43 35 59.26 44.2c3.48 2 4.62-5.4 5.69-7.25 2.21-3.83 3.26-7.51 4.7-7.38.64.06 1 .86 1.24 1.22 6.24 12.44 7.73 17.85 7.73 17.85 3.23 11.73 5 13.42 3.54 15.18s-3.44.46-14.71.1c-7.69-.24-10.67.24-16.86-1a58.7 58.7 0 01-6.44-1.63A2.06 2.06 0 0143.2 60a2.22 2.22 0 011.8-2.28 47.77 47.77 0 005.22-3.66 48.48 48.48 0 005.11-4.72C51.65 45.8 43 40.48 33.47 31.46 31 29.11 19.85 18.18 17.07 14.29 13.08 8.73 0 .14 0 .14s2.47-1.56 14.18 6.07z"
          fill="#fff"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgExpand3;
