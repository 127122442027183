import * as React from 'react';

function SvgDecorationTimeline1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.46 105.72"
      {...props}
    >
      <defs>
        <style />
      </defs>
      <g id="DecorationTimeline1_svg__Layer_2" data-name="Layer 2">
        <g
          id="DecorationTimeline1_svg__Layer_1-2"
          data-name="Layer 1"
          fill="currentColor"
        >
          <path d="M58.38 97.27c-.8-4.27-2.12-10.91-4.05-18.86-2-8.27-3.47-13.6-6.8-27.23-2.85-11.7-4.23-18-3-26.13A45.73 45.73 0 0157.18 0a50.86 50.86 0 0110.39 18.05 49.53 49.53 0 012 9c2 14.94-2.16 27.62-7 49.69-1.03 4.54-2.57 11.64-4.19 20.53zM95.26 83.08c5.17-.36 9.9 1.24 15.2 1.72a63.45 63.45 0 01-4.85 4.79 34.68 34.68 0 01-4 3.31c-5.49 3.49-12.79 1.4-12.82 1.71s5.77.22 11.11 3.42a13.84 13.84 0 015.09 4.58c.43.72 1.74 3.11 1.74 3.11S101.3 105 96.44 104c-4-.85-4.06-1.18-7-1.82a63.07 63.07 0 00-19.46-.74s1.76-3 2.57-4.28a69.09 69.09 0 014.54-6.51 40.66 40.66 0 019.13-8.87c2-1.39 5.42-3.36 9.4-6A11.8 11.8 0 0099 72.39a6.51 6.51 0 00.86-1.71c.1-.35 1-3.36-.86-5.13a4.8 4.8 0 00-4.27-.85 7.78 7.78 0 013.42-2.57 6.57 6.57 0 015.13 0 6.16 6.16 0 012.56 2.57c2.1 3.57.68 7.73.3 8.7a11.23 11.23 0 01-3.39 4.83c-2.33 1.83-4.98 3.25-7.49 4.85zM46 100.74s-1 .48-2.43 1A21.7 21.7 0 0138.3 103c-2 .18-2 .22-8.23-1.21-.78-.19-1.56-.33-2.33-.45a31.18 31.18 0 00-3.82-.3 26.14 26.14 0 00-8 .6 17.42 17.42 0 00-5.3 2.46 11.71 11.71 0 011.09-3.65 11.45 11.45 0 011.88-2.67 8.84 8.84 0 011.93-1.74 8.57 8.57 0 011.65-.82s-3-1.76-5.8-3.14c-3-1.47-2.69-1-5-2.17-3.87-2-5-3.58-5.54-4.59A9.29 9.29 0 010 83a3.1 3.1 0 00.8 1 3.56 3.56 0 001.42.66 6.45 6.45 0 001.54.22c1.17 0 5.6-.3 11.59-.9a18.77 18.77 0 01-4-4.89 19.87 19.87 0 01-1.48-3.8 19.06 19.06 0 01-.68-2.83 12.22 12.22 0 01.38-6.1 8.17 8.17 0 013-4.31A7.43 7.43 0 0118.5 61a10.51 10.51 0 014.05 3 11.62 11.62 0 00-5.23 1.53 3 3 0 00-.91.87 4.58 4.58 0 00-.41 3.25 17.15 17.15 0 004.28 8.52c7.21 7.53 13.27 8.32 20 15.09a37 37 0 015.72 7.48zM43.74 84.75zM43.74 84.75z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgDecorationTimeline1;
