import React from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Copyright, MobileProgress, WallMobile } from '../components';
import { Header, ExpandButton } from '../modals';
import * as icons from '../icons';
import { useContainerDimensions } from '../hooks';
import { Context } from '../context';
import Quotawall3D from '../assets/images/quotawall-3d.png';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  EmailShareButton,
  EmailIcon,
  PocketShareButton,
  PocketIcon,
} from 'react-share';
import { FaqContent } from '../modals/Faq';
import { IntroContent } from '../modals/Intro';
import { Contacts } from '../modals/Mailing';

const Widget = ({ className, children, ...props }) => (
  <div
    className={`my-2 p-4 rounded shadow-lg ${className ? className : ''}`}
    {...props}
  >
    {children}
  </div>
);

const WidgetWithHeader = ({
  title,
  children,
  expandButton = true,
  className = '',
  headerClassName = 'bg-quotables-blue text-white',
  ...props
}) => (
  <div
    className={`my-2 rounded shadow-lg flex flex-col ${className}`}
    {...props}
  >
    <div
      className={`py-2 px-4 flex justify-between items-center rounded-t ${headerClassName}`}
    >
      <Header>{title}</Header>
    </div>
    <div className="flex-grow bg-white text-black rounded-b">
      {children}
      {expandButton && (
        <div className="flex flex-row items-center justify-end m-2">
          <ExpandButton className="bg-quotables-blue text-white" />
        </div>
      )}
    </div>
  </div>
);

const Window = ({
  title,
  children,
  className = '',
  headerClassName = 'bg-quotables-blue text-white',
  onClose,
  ...props
}) => (
  <div className={`flex flex-col ${className}`} {...props}>
    <div
      className={`py-2 px-4 flex justify-between items-center rounded-t ${headerClassName}`}
    >
      <div className="flex-grow" />
      <icons.Decoration1 className="h-6 w-6 transform rotate-180" />
      <h1 className="font-semibold text-normal text-center mx-4">{title}</h1>
      <icons.Decoration1 className="h-6 w-6" />
      <div className="flex-grow" />
      <button onClick={onClose}>
        <icons.Close className="h-4 w-4" />
      </button>
    </div>
    <div className="flex-grow bg-white text-black rounded-b">{children}</div>
  </div>
);

const MobileScreen = () => {
  const history = useHistory();
  const wallRef = React.useRef();
  const { width: wallWidth } = useContainerDimensions(wallRef);
  const handleShareClick = (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          url: process.env.REACT_APP_OG_URL,
          title: 'Quotables',
          text: 'The Quotables are NFT versions of the traditional Dutch tile sayings, also known as “tegelspreuken”',
        })
        .then(() => console.log('Share was successful.'))
        .catch((error) => console.log('Sharing failed', error));
    } else {
      history.push('/mobile/share');
    }
  };
  const showSubscribe = () => history.push('/mobile/subscribe');
  return (
    <div className="w-full min-h-full flex flex-col p-2 bg-quotables-light-blue">
      <div className="flex flex-row my-2">
        <icons.Brand className="h-12 w-40" />
        <div className="flex-grow" />
        <div className="flex flex-row items-center p-2 mr-2 bg-white rounded shadow-lg">
          <icons.Mail
            className="h-8 w-8 cursor-pointer"
            onClick={showSubscribe}
          />
        </div>
        <div className="flex flex-row items-center p-2 bg-white rounded shadow-lg">
          <icons.Twitter className="h-8 w-8 cursor-pointer" />
        </div>
      </div>

      <WidgetWithHeader
        title="Introducing Quotables"
        className="bg-white text-quotables-blue"
        headerClassName="bg-white text-quotables-blue"
        onClick={() => history.push('/mobile/intro')}
      >
        <div className="text-sm p-4 pb-0 leading-relaxed text-quotables-light-gray text-center">
          The Quotables are NFT versions of the traditional Dutch tile sayings,
          also known as “tegelspreuken”. These tiles have an interesting
          history, combining the art of tin-glazed pottery and the love for
          proverbs and aphorisms deeply rooted in Dutch culture. We're offering
          the experience of making your own tile with your favorite quote, turn
          in into an NFT and put it up on the Quotawall. Share the wisdom and
          spread the word!
        </div>
      </WidgetWithHeader>

      <Widget className="flex flex-row justify-center bg-quotables-blue text-white text-sm text-center">
        Visit this website from a PC or Mac to buy your Quotables
      </Widget>

      <Widget
        className="flex flex-row items-center justify-center bg-quotables-orange text-white px-4 py-2"
        onClick={handleShareClick}
      >
        <icons.Speaker className="h-6 w-6 mr-2" />
        <span className="uppercase font-semibold text-xl flex-grow text-center">
          Spread the word
        </span>
        <span className="p-2 rounded-full bg-white text-quotables-orange">
          <icons.Share className="h-4 w-4" />
        </span>
      </Widget>

      <WidgetWithHeader
        title="Quotawall"
        expandButton={false}
        onClick={() => history.push('/mobile/zoom')}
        style={{ height: '300px' }}
      >
        <div
          ref={wallRef}
          className="w-full h-full text-quotables-blue bg-no-repeat p-4 flex flex-col"
          style={{
            backgroundImage: `url(${Quotawall3D})`,
            backgroundPositionX:
              wallWidth < 400 ? `right ${-wallWidth / 3}px` : 'right',
            backgroundSize: wallWidth < 400 ? 'cover' : 'contain',
          }}
        >
          <h2 className="font-semibold mb-2">
            Immortalise yourself
            <br />
            on the Quotawall
          </h2>
          <p className="text-sm text-quotables-light-gray leading-relaxed">
            Co-create art & share
            <br />
            wisdom. Revive a piece
            <br />
            of history.
          </p>
          <div className="flex-grow" />
          <div className="flex flex-row items-center justify-end">
            <ExpandButton className="bg-quotables-blue text-white" />
          </div>
        </div>
      </WidgetWithHeader>

      <WidgetWithHeader
        title="FAQ"
        className="h-[400px]"
        onClick={() => history.push('/mobile/faq')}
      >
        <div className="w-full h-full text-quotables-blue flex flex-col p-4 pb-0 text-sm">
          <div className="flex flex-row items-center p-2">
            <icons.QuestionMark className="h-6 w-6" />
            <h2 className="ml-2">How It Works</h2>
          </div>
          <div className="flex flex-row items-center p-2">
            <icons.ExclamationMark className="h-6 w-6" />
            <h2 className="ml-2">Rules for the Content</h2>
          </div>
          <div className="flex flex-row items-center p-2">
            <icons.CheckMark className="h-6 w-6" />
            <h2 className="ml-2">Verified Smart Contracts</h2>
          </div>
        </div>
      </WidgetWithHeader>

      <Widget className="bg-white">
        <MobileProgress className="h-[400px] relative" />
      </Widget>

      <div className="flex flex-row justify-center my-2">
        <Copyright className="text-sm font-semibold" />
      </div>
    </div>
  );
};

const IntroScreen = () => {
  const history = useHistory();
  const onClose = () => history.push('/mobile');
  return (
    <Window
      title="Introducing Quotables"
      className="h-full w-full bg-quotables-blue"
      onClose={onClose}
    >
      <section className="p-6 text-sm text-quotables-gray leading-relaxed text-justify">
        <IntroContent />
      </section>
    </Window>
  );
};

const ZoomScreen = () => {
  const history = useHistory();
  const onClose = () => history.push('/mobile');
  return (
    <Window
      title="Quotawall"
      className="h-full w-full bg-quotables-blue"
      onClose={onClose}
    >
      <WallMobile />
    </Window>
  );
};

const FaqScreen = () => {
  const history = useHistory();
  const onClose = () => history.push('/mobile');
  return (
    <Window
      title="FAQ"
      className="h-full w-full bg-quotables-blue"
      onClose={onClose}
    >
      <div className="p-4 pt-0 pb-8 text-sm leading-relaxed">
        <FaqContent />
      </div>
    </Window>
  );
};

const MailingScreen = () => {
  const history = useHistory();
  const onClose = () => history.push('/mobile');
  return (
    <Window
      title="Priority Mail"
      className="h-full w-full bg-quotables-blue"
      onClose={onClose}
    >
      <div className="p-8 text-base leading-relaxed">
        <Contacts onHide={onClose} />
      </div>
    </Window>
  );
};

const ShareScreen = () => {
  const history = useHistory();
  const onClose = () => history.push('/mobile');
  const shareUrl = process.env.REACT_APP_OG_URL;
  const shareMediaUrl = `${shareUrl}/brand.png`;
  const shareTitle = 'Quotables';
  const btnClass = 'm-4 outline-none';
  const iconSize = 64;
  return (
    <Window
      title="Spread the word"
      className="h-full w-full bg-quotables-blue"
      onClose={onClose}
    >
      <div className="p-4 text-sm leading-relaxed">
        <div className="flex flex-row flex-wrap items-center justify-center">
          <FacebookShareButton
            url={shareUrl}
            quote={shareTitle}
            className={btnClass}
          >
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>

          <FacebookMessengerShareButton
            url={shareUrl}
            appId="521270401588372"
            className={btnClass}
          >
            <FacebookMessengerIcon size={iconSize} round />
          </FacebookMessengerShareButton>

          <TwitterShareButton
            url={shareUrl}
            title={shareTitle}
            className={btnClass}
          >
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={shareTitle}
            className={btnClass}
          >
            <TelegramIcon size={iconSize} round />
          </TelegramShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title={shareTitle}
            separator=":: "
            className={btnClass}
          >
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>

          <LinkedinShareButton url={shareUrl} className={btnClass}>
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>

          <PinterestShareButton
            url={shareUrl}
            media={shareMediaUrl}
            className={btnClass}
          >
            <PinterestIcon size={iconSize} round />
          </PinterestShareButton>

          <RedditShareButton
            url={shareUrl}
            title={shareTitle}
            windowWidth={660}
            windowHeight={460}
            className={btnClass}
          >
            <RedditIcon size={iconSize} round />
          </RedditShareButton>

          <TumblrShareButton
            url={shareUrl}
            title={shareTitle}
            className={btnClass}
          >
            <TumblrIcon size={iconSize} round />
          </TumblrShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={shareTitle}
            body=""
            className={btnClass}
          >
            <EmailIcon size={iconSize} round />
          </EmailShareButton>

          <PocketShareButton
            url={shareUrl}
            title={shareTitle}
            className={btnClass}
          >
            <PocketIcon size={iconSize} round />
          </PocketShareButton>
        </div>
      </div>
    </Window>
  );
};

const MobileRouter = (props) => (
  <Context {...props}>
    <Switch>
      <Route exact path="/mobile" component={MobileScreen} />
      <Route exact path="/mobile/intro" component={IntroScreen} />
      <Route exact path="/mobile/zoom" component={ZoomScreen} />
      <Route exact path="/mobile/faq" component={FaqScreen} />
      <Route exact path="/mobile/share" component={ShareScreen} />
      <Route exact path="/mobile/subscribe" component={MailingScreen} />
      <Route>
        <Redirect to="/mobile" />
      </Route>
    </Switch>
  </Context>
);

export default MobileRouter;
