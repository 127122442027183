import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core'
import { useEagerConnect, useInactiveListener, useIsMobile } from './hooks'
import { getLibrary } from './blockchain'

import * as screens from './screens';

function DesktopOnly({ children, to }) {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <Redirect to={to} />
  }
  return children;
}

function MobileOnly({ children, to }) {
  const isMobile = useIsMobile();
  if (!isMobile) {
    return <Redirect to={to} />
  }
  return children;
}

function App() {
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <DesktopOnly to="/mobile">
            <screens.Windows />
          </DesktopOnly>
        </Route>
        <Route path="/mobile">
          <MobileOnly to="/">
            <screens.Mobile />
          </MobileOnly>
        </Route>
        <Route path="/r/:id">
          {({match, ...rest}) => match ? (
            <DesktopOnly to="/mobile">
              <screens.Windows currentModal="wall" previewId={match.params.id} />
            </DesktopOnly>
          ) : null}
        </Route>
        <Route exact path="/countdown" component={screens.WindowsLocked} />
        <Route exact path="/coming" component={screens.WindowsSoon} />
        <Route exact path="/windows" component={screens.Windows} />
        <Route exact path="/zoom" component={screens.Zoom} />
        <Route exact path="/icons" component={screens.Icons} />
        <Route exact path="/test" component={screens.Test} />
        <Route component={screens.NotFound} />
      </Switch>
    </Router>
  );
}

export default function WrappedApp() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  );
};
