import { memo } from 'react';
import { Text } from './Text';
import TileImg0 from '../assets/images/Tile0.png';
import TileImg1 from '../assets/images/Tile1.png';
import TileImg2 from '../assets/images/Tile2.png';
import TileImg3 from '../assets/images/Tile3.png';
import TileImg4 from '../assets/images/Tile4.png';
import TileImg5 from '../assets/images/Tile5.png';
import TileImgSmall0 from '../assets/images/Tile0Thumbnail.png';
import TileImgSmall1 from '../assets/images/Tile1Thumbnail.png';
import TileImgSmall2 from '../assets/images/Tile2Thumbnail.png';
import TileImgSmall3 from '../assets/images/Tile3Thumbnail.png';
import TileImgSmall4 from '../assets/images/Tile4Thumbnail.png';
import TileImgSmall5 from '../assets/images/Tile5Thumbnail.png';
import TileImgTiny1 from '../assets/images/Tile1blur.png';
import TileImgTiny2 from '../assets/images/Tile2blur.png';
import TileImgTiny3 from '../assets/images/Tile3blur.png';
import TileImgTiny4 from '../assets/images/Tile4blur.png';
import TileImgTiny5 from '../assets/images/Tile5blur.png';

const BG_IMAGE_MAP = {
  0: TileImg0,
  1: TileImg1,
  2: TileImg2,
  3: TileImg3,
  4: TileImg4,
  5: TileImg5,
};

const BG_IMAGE_SMALL_MAP = {
  0: TileImgSmall0,
  1: TileImgSmall1,
  2: TileImgSmall2,
  3: TileImgSmall3,
  4: TileImgSmall4,
  5: TileImgSmall5,
};

const BG_IMAGE_TINY_MAP = {
  0: TileImgSmall0,
  1: TileImgTiny1,
  2: TileImgTiny2,
  3: TileImgTiny3,
  4: TileImgTiny4,
  5: TileImgTiny5,
};

const fontFamily = "'Della Respira', serif";
const fontSize = '40px';
const canvasSize = 640;
const textWidth = 400;

function TilePreview({ id, size, text, children, bg, scale }) {
  const mapping =
    scale === 'tiny'
      ? BG_IMAGE_TINY_MAP
      : scale === 'small'
      ? BG_IMAGE_SMALL_MAP
      : BG_IMAGE_MAP;
  const tileBg = mapping[bg];
  if (bg === 0 || scale === 'tiny') {
    return <img src={tileBg} width={size} height={size} alt="" />;
  }
  return (
    <svg
      viewBox={`0 0 ${canvasSize} ${canvasSize}`}
      width={size}
      height={size}
    >
      <image x={0} y={0} width={canvasSize} height={canvasSize} href={tileBg} />
      <Text
        x={canvasSize / 2}
        y={canvasSize / 2}
        width={textWidth}
        textAnchor="middle"
        verticalAnchor="middle"
        lineHeight="1em"
        style={{
          fontSize: fontSize,
          fontFamily: fontFamily,
          lineHeight: '1em',
          pointerEvents: 'auto',
        }}
        data-tip={id}
      >
        {children ? children : text}
      </Text>
    </svg>
  );
}

export default memo(TilePreview);
