import * as React from 'react';

function SvgMetaMaskDisabled(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 33"
      {...props}
    >
      <filter id="MetaMaskDisabled_svg__a" filterUnits="objectBoundingBox">
        <feColorMatrix type="saturate" in="SourceGraphic" values={0.2} />
      </filter>
      <path
        d="M32.958 1l-13.134 9.718 2.443-5.727L32.958 1z"
        fill="#E17726"
        stroke="#E17726"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M2.663 1l13.017 9.809-2.325-5.818L2.663 1zM28.23 23.533l-3.495 5.339 7.483 2.06 2.143-7.282-6.132-.117zM1.273 23.65l2.13 7.282 7.47-2.06-3.481-5.339-6.12.117z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M10.47 14.515L8.392 17.65l7.405.337-.247-7.97-5.08 4.497zM25.15 14.515l-5.157-4.587-.169 8.06 7.405-.337-2.079-3.136zM10.873 28.872l4.482-2.164-3.858-3.006-.624 5.17zM20.266 26.708l4.469 2.164-.61-5.17-3.86 3.006z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M24.735 28.872l-4.47-2.164.365 2.903-.04 1.23 4.145-1.969zM10.873 28.872l4.158 1.97-.027-1.231.351-2.903-4.482 2.164z"
        fill="#D5BFB2"
        stroke="#D5BFB2"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M15.108 21.784l-3.715-1.088 2.624-1.205 1.091 2.293zM20.513 21.784l1.091-2.293 2.637 1.205-3.728 1.088z"
        fill="#233447"
        stroke="#233447"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M10.873 28.872l.65-5.339-4.131.117 3.481 5.222zM24.098 23.533l.637 5.339 3.494-5.222-4.13-.117zM27.229 17.65l-7.405.338.688 3.796 1.092-2.293 2.637 1.205 2.988-3.045zM11.393 20.696l2.624-1.205 1.091 2.293.689-3.796-7.405-.337 3 3.045z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M8.392 17.65l3.105 6.052-.104-3.006-3-3.045zM24.241 20.696l-.117 3.006 3.105-6.051-2.988 3.045zM15.797 17.988l-.688 3.796.87 4.483.195-5.908-.377-2.372zM19.824 17.988l-.364 2.358.182 5.921.87-4.483-.688-3.796z"
        fill="#E27525"
        stroke="#E27525"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M20.513 21.784l-.87 4.484.623.44 3.858-3.006.117-3.006-3.728 1.088zM11.393 20.696l.104 3.006 3.858 3.006.624-.44-.87-4.484-3.716-1.088z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M20.59 30.842l.04-1.231-.338-.285h-4.963l-.325.285.027 1.23-4.158-1.969 1.455 1.192 2.95 2.035h5.053l2.962-2.035 1.442-1.192-4.144 1.97z"
        fill="#C0AC9D"
        stroke="#C0AC9D"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M20.266 26.708l-.624-.44H15.98l-.624.44-.35 2.903.324-.285h4.963l.338.285-.364-2.903z"
        fill="#161616"
        stroke="#161616"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M33.517 11.353L34.62 5.99 32.958 1l-12.692 9.394 4.885 4.12 6.898 2.01 1.52-1.776-.663-.48 1.053-.958-.806-.622 1.052-.804-.688-.53zM1 5.989l1.117 5.364-.714.531 1.065.804-.805.622 1.052.959-.663.48 1.52 1.774 6.899-2.008 4.884-4.12L2.663 1 1 5.989z"
        fill="#763E1A"
        stroke="#763E1A"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        d="M32.049 16.523l-6.898-2.008 2.078 3.136-3.105 6.05 4.106-.05h6.131l-2.312-7.128zM10.47 14.515l-6.898 2.008-2.3 7.127h6.12l4.105.052-3.105-6.051 2.078-3.136zM19.824 17.988l.442-7.594 2-5.403h-8.911l2 5.403.442 7.594.169 2.384.013 5.896h3.663l.013-5.896.17-2.384z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeWidth={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#MetaMaskDisabled_svg__a)"
      />
      <path
        fill="#fff"
        d="M6.02.74c-12.15 6.68-3.01 19.93 5.96 16.52C21.67 13.58 18.84-2.05 6.02.74z"
      />
      <path
        fill="#ED9121"
        d="M13.34 9.64V8.41c0-.16-.06-.31-.18-.43a.62.62 0 00-.44-.18h-2.46V5.33c0-.16-.06-.31-.18-.43a.604.604 0 00-.44-.18H8.41c-.16 0-.31.06-.43.18s-.18.27-.18.43V7.8H5.33c-.16 0-.31.06-.43.18s-.18.27-.18.43v1.23c0 .17.06.32.18.44s.27.18.43.18H7.8v2.46c0 .17.06.31.18.44.12.12.27.18.43.18h1.23c.17 0 .32-.06.44-.18a.62.62 0 00.18-.44v-2.46h2.46c.17 0 .31-.06.44-.18.12-.12.18-.27.18-.44zm3.08-.61c0 1.34-.33 2.58-.99 3.71a7.382 7.382 0 01-2.69 2.69c-1.13.66-2.37.99-3.71.99-1.34 0-2.58-.33-3.71-.99a7.382 7.382 0 01-2.69-2.69 7.233 7.233 0 01-.99-3.71c0-1.34.33-2.58.99-3.71a7.382 7.382 0 012.69-2.69c1.13-.66 2.37-.99 3.71-.99 1.34 0 2.58.33 3.71.99 1.13.66 2.03 1.56 2.69 2.69.66 1.13.99 2.37.99 3.71z"
      />
    </svg>
  );
}

export default SvgMetaMaskDisabled;
