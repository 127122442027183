import React from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import TilePreview from './TilePreview';
import { useTileMap } from './Zoom';

function TileGrid() {
  const data = useTileMap();
  return (
    <div className="w-full h-full overflow-hidden">
      <AutoSizer>
        {({ width, height }) => {
          const cols = width > height ? 4 : 2;
          const rows = Math.floor(2048 / cols);
          const tileSize = Math.floor(width / cols);
          return (
            <Grid
              columnCount={cols}
              columnWidth={tileSize}
              height={height}
              rowCount={rows}
              rowHeight={tileSize}
              width={width}
            >
              {({ columnIndex, rowIndex, style }) => {
                const idx = rowIndex * cols + columnIndex;
                const tile = data[idx];
                return (
                  <div style={style}>
                    <TilePreview
                      key={idx}
                      size={tileSize}
                      text={tile.title}
                      bg={tile.bg}
                    />
                  </div>
                );
              }}
            </Grid>
          );
        }}
      </AutoSizer>
    </div>
  );
}

export default TileGrid;
