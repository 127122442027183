import { useContext } from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal } from '../components';
import { Header } from './Base';

function Alert() {
  const { currentModal, message } = useContext(StateContext);
  const { onHide } = useContext(DispatchContext);
  const isOpen = currentModal === 'alert';
  return (
    <Modal isOpen={isOpen} onRequestClose={onHide}>
      <header className="flex flex-row justify-between mt-4 p-4">
        <Header decoClassName="text-quotables-orange">Error</Header>
      </header>
      <section className="p-6 pt-0 text-xs text-quotables-gray leading-relaxed">
        <p className="mb-2">{message}</p>
      </section>
    </Modal>
  );
}

export default Alert;
