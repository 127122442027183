import { Zoom } from '../components';

function ZoomScreen() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Zoom />
    </div>
  );
}

export default ZoomScreen;
