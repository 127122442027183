import * as icons from '../icons';

function WidgetBase({ width, height, children }) {
  return (
    <div
      className="flex flex-col rounded overflow-hidden shadow-2xl border border-quotables-light-gray border-opacity-50"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      {children}
    </div>
  );
}

export function Widget({ inline, ...props }) {
  if (inline) {
    return (
      <div className="inline-block">
        <WidgetBase {...props} />
      </div>
    );
  }
  return <WidgetBase {...props} />;
}

export function Header({ decoClassName, children, rem }) {
  return (
    <>
      <icons.Decoration1
        className={`h-6 w-6 ${decoClassName}`}
        style={{ width: `${1.5 * rem}px`, height: `${1.5 * rem}px` }}
      />
      <h1
        className="font-semibold text-xl text-center"
        style={{ fontSize: `${1.25 * rem}px`, lineHeight: `${1.75 * rem}px` }}
      >
        {children}
      </h1>
      <icons.Decoration1
        className={`h-6 w-6 transform rotate-180 ${decoClassName}`}
        style={{ width: `${1.5 * rem}px`, height: `${1.5 * rem}px` }}
      />
    </>
  );
}

export function BlueWindow({ title, dragRef, rem, children }) {
  return (
    <>
      <div
        className="cursor-move py-2 px-4 bg-quotables-blue text-white flex justify-between items-center"
        ref={dragRef}
      >
        <Header rem={rem}>{title}</Header>
      </div>
      <div className="flex-grow bg-white text-black">{children}</div>
    </>
  );
}

export function ExpandButton({ className, rem, ...props }) {
  return (
    <span
      className={`p-2 rounded-full cursor-pointer ${className}`}
      style={{ padding: `${0.5 * rem}px` }}
      {...props}
    >
      <icons.Expand4
        className="h-4 w-4"
        style={{ width: `${1.0 * rem}px`, height: `${1.0 * rem}px` }}
      />
    </span>
  );
}
