import * as React from 'react';

function SvgDecorationTimeline2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 119.53 113.54"
      {...props}
    >
      <defs>
        <style />
      </defs>
      <g id="DecorationTimeline2_svg__Layer_2" data-name="Layer 2">
        <g
          id="DecorationTimeline2_svg__Layer_1-2"
          data-name="Layer 1"
          fill="currentColor"
        >
          <path d="M81.78 23.73a28.06 28.06 0 00-2.28-5 24.85 24.85 0 00-5.72-6.57 38 38 0 01.57 5.14 35.85 35.85 0 01-1.15 10.75 27.79 27.79 0 01-6.29 11.51c.29-2 .59-3.53.81-4.62s.36-1.59.51-2.54a35.57 35.57 0 00.4-4c.3-7.21-2.27-13.79-3.17-16s-.94-1.89-2-4.49c-.92-2.31-2.22-4.38-3-6.76C60.35.83 60.08 0 59.71 0s-.56.39-.79 1.15c-1.31 4.3-3.16 8-4.72 12-.84 2.18-4.2 10.64-2.66 19.85a27 27 0 002.22 7 18.5 18.5 0 01-6.36-10 35.73 35.73 0 01-.5-10.9c.11-1.24.57-5.43.57-5.43a23.39 23.39 0 00-8.26 11.13c-3.07 8.74-1 22.26 6.92 32.16 1.25 1.56 1.07 1.08 3.06 3.45 9 10.78 9.75 17.42 12.58 17.27 2.45-.14 2.61-5.16 9.72-16.55 1.63-2.61 1.57-2.26 2.78-4.22 6.47-10.49 10.45-24.09 7.51-33.18zM40.57 45.27a.94.94 0 010 .16h-.1a1.22 1.22 0 010-.17s.08.02.1.01zM40.71 108a11.29 11.29 0 01-8.44 5.48c-3.57.37-6.64-1.39-10.64-3.86-1.3-.8-2.56-1.72-3.83-2.6-4.38-3-9.13-3.46-14-2.75a4.36 4.36 0 00-1.15.15C.9 105 .1 106.8 0 106.72a10.15 10.15 0 012.18-3.57s.47-.55.93-1c2.31-2.19 7.12-3.33 7.67-3.46a7.71 7.71 0 01-3.92-4.81 9.4 9.4 0 010-3.38A9.14 9.14 0 008 93.21a3.63 3.63 0 002.69 1.24 8.76 8.76 0 002 0 14.37 14.37 0 004.44-1.28c6.1-3 9.51-6.62 13.73-6.75 3.6-.12 7.1 2.29 9.15 5.4 2.91 4.42 3.59 11.27.7 16.18zM119.53 97.26a5.38 5.38 0 00-2.29-2c-4.28-1.77-11 4.53-14.54 9.35 0 0-4.17 5.64-7.31 6.73-.82.29-1.67.49-1.67.49a20.24 20.24 0 01-2.13.39 11.11 11.11 0 01-7.82-2.63 13.53 13.53 0 01-4.14-8.09c-.66-4.89 1.2-10.16 4.54-12.61 4.66-3.43 10.91-.44 12 .07 2.24 1.07 3.69 2.5 6.8 2.89a8.68 8.68 0 004.69-.58 6.88 6.88 0 003.89-3.44c.28-.67.43-1.38.57-1.35s.26 1.51 0 2.7a6.86 6.86 0 01-2.86 4 7.12 7.12 0 016.86 0 8.75 8.75 0 013.41 4.08zM65.87 105.14c-2.69 4.45-2.77 7.62-4.17 7.66s-1.58-3.33-4.63-7.66c-3.31-4.71-6-4.73-6-7.12-.09-3 4.12-3.39 7-9.21 2-4.1 1.78-7.73 2.74-7.76s.88 3.77 3.15 7.66c3.22 5.48 7.78 5.18 8 8.22.09 2.43-2.82 2.81-6.09 8.21zM37.75 50.75h-.1a.76.76 0 010-.16h.1z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgDecorationTimeline2;
