import { useContext } from 'react';
import { TilePreview, OpenSeaLink } from '../components';
import { StateContext } from '../context';
import { Widget, Header } from './Base';

function RandomTile({ item: { width, height, rem }, dragRef, inline }) {
  const { randomTile } = useContext(StateContext);
  return (
    <Widget width={width} height={height} inline={inline}>
      <div className="h-full text-quotables-blue bg-white flex flex-col">
        <div
          className="flex flex-row p-4 justify-between items-center cursor-move"
          ref={dragRef}
          style={{ padding: `${1.0 * rem}px` }}
        >
          <Header rem={rem}>Daily Quote</Header>
        </div>
        <div className="flex flex-col items-center">
          <TilePreview size="75%" text={randomTile.title} bg={randomTile.bg} />
          {randomTile.id && (
            <OpenSeaLink
              id={randomTile.id}
              className="text-xs my-1 hover:underline"
              style={{
                fontSize: `${0.75 * rem}px`,
                margin: `${0.25 * rem}px 0`,
              }}
            >
              Quotable {randomTile.id}/2048
            </OpenSeaLink>
          )}
          {randomTile.owner && (
            <OpenSeaLink
              id={randomTile.id}
              className="text-xs truncate w-1/2 hover:underline"
              style={{ fontSize: `${0.75 * rem}px` }}
            >
              Owner {randomTile.owner}
            </OpenSeaLink>
          )}
        </div>
      </div>
    </Widget>
  );
}

export default RandomTile;
