import * as React from 'react';

function SvgExclamationMark(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 183.56 157.44"
      {...props}
    >
      <path
        d="M181.51 142.51c-.42-5.08-2.66-8.63-5.82-14.06-2.07-3.55-2.37-4.19-3.53-6.3-1.66-3-4-7.11-5.13-9-30-51.06-53.94-81.52-53.94-81.53a549.749 549.749 0 00-15.96-19.34c-3.84-4-6.5-6.57-8.32-8.14S86.03 2 85.57 2h-1.69c-4.94 1.85-9 7.33-9 7.33-1.4 1.87-4.34 6.23-8.34 12.56s-9 14.61-14.63 24.32c-26.08 50.74-45 87.92-46.44 91.22-.28.64-1 2.58-2.26 5.64a14.14 14.14 0 00-1.13 3.39 10.68 10.68 0 00.57 4.51c2.76 7.68 158.18 3.83 172.35.46a8.86 8.86 0 005.41-3.28 8.51 8.51 0 001.1-5.64zm-3.95 2.82a2.91 2.91 0 01-1.13 1.13c-4.28 2.4-164.54 2.38-167 1.13 0 0-.48-.26-1.13-.57-.8-.37-1-.4-1.13-.56-.63-.66.14-2.36.29-2.67 1-2.18 15.16-31 34.57-70.31 0 0 28.78-55.6 40.76-68.1a2.06 2.06 0 011-.69c1-.21 2.07.89 2.41 1.26 22.87 24.36 46.27 60.94 46.27 60.94s40.78 63.75 45.15 76.18c.49 1.57-.06 2.26-.06 2.26z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M86.5 42.07c3.52.07 7 0 10.55.27 2.37.17 3.56 1.58 3.74 4a30.91 30.91 0 010 5.77c-.84 7.71-1.5 15.44-2.79 23.09-3 17.78-4.53 26.67-4.5 28.93 0 2.8-1 5.55-1.08 8.35a2.43 2.43 0 01-1.75 2.42 8.67 8.67 0 01-5.94-.24 2.32 2.32 0 01-1.05-1.59c-1-6.18-1.92-12.39-2.89-18.58-.89-5.68-1.82-11.34-2.73-17-.65-4.07-1.46-8.12-1.91-12.22-.61-5.58-1-11.2-1.4-16.8-.12-1.49-.08-3-.09-4.49a1.86 1.86 0 01.22-.91 2.82 2.82 0 012.23-1.09 62 62 0 019.39.09zm11.24 2.69a7.66 7.66 0 00-2.95-.37h-1.13c-3.24 0-6.48-.06-9.73-.07a44 44 0 00-5.78.1c-1.86 1.32-1.62 4.5.27 18.78.57 4.36.84 6.54 1.07 7.94 1.45 8.75 1.92 11 3.1 18.15 1.92 11.66 3.09 22.78 3.09 22.78h0a4.31 4.31 0 002.82.56 4.44 4.44 0 001.7-1.13h0c2.61-15.55 3.82-31.34 6.77-46.84-.03-.03 3.62-19.1.77-19.9zM83.96 122.76a5.7 5.7 0 012.82-2.26 5.6 5.6 0 013.39 0 6.59 6.59 0 012.82 1.69 7.42 7.42 0 011.69 6.21 6.08 6.08 0 01-2.26 3.95 6.25 6.25 0 01-5.64.57 6.56 6.56 0 01-2.82-10.16zm8.46 2.26a4 4 0 00-3.37-2.25 4.21 4.21 0 00-4 2.81 4.31 4.31 0 000 2.82 3.67 3.67 0 001.13 1.69 3.83 3.83 0 001.77.85 4.11 4.11 0 004.43-5.92z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
    </svg>
  );
}

export default SvgExclamationMark;
