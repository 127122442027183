import { default as Faq } from './Faq';
import { default as Intro } from './Intro';
import { default as Quotawall } from './Quotawall';
import { default as Wallet } from './Wallet';
import { default as Buy } from './Buy';
import { default as Alert } from './Alert';
import { default as BuySucceed } from './BuySucceed';
import { default as Mailing } from './Mailing';

export function Modals() {
  return (
    <>
      <Faq />
      <Intro />
      <Quotawall />
      <Wallet />
      <Buy />
      <Alert />
      <BuySucceed />
      <Mailing />
    </>
  );
}

export { Faq, Intro, Quotawall, Wallet, Buy, Alert, BuySucceed, Mailing };
export * from './Base';
