import React from 'react';
import { animated, to } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import * as icons from '../icons';

const roundZeroDp = (value) => Math.floor(value).toFixed(0);
const clamp = (value, min, max) => Math.max(Math.min(value, max), min);

const Scroll = ({ value, minValue, maxValue, setValue }) => {
  const ref = React.useRef();
  const bind = useDrag(
    ({ down, xy: [x] }) => {
      const bound = ref.current.getBoundingClientRect();
      const percent = (x - bound.left) / bound.width;
      const newValue = Math.floor(minValue + percent * (maxValue - minValue));
      setValue(clamp(newValue, minValue, maxValue));
    },
    { axis: 'x' },
  );
  const clickHandler = (e) => {
    e.stopPropagation();
    const bound = ref.current.getBoundingClientRect();
    const x = e.clientX;
    const percent = (x - bound.left) / bound.width;
    const newValue = Math.floor(minValue + percent * (maxValue - minValue));
    setValue(clamp(newValue, minValue, maxValue));
  };
  const valuePercent = to(
    [value],
    (v) => `${roundZeroDp(((v - minValue) / (maxValue - minValue)) * 100)}%`,
  );
  return (
    <div className="p-2 flex flex-row px-4 bg-gray-500 items-center flex-grow">
      <div
        className="h-1 w-full mx-4 bg-white overflow-visible rounded relative"
        onClick={clickHandler}
        ref={ref}
      >
        <animated.div
          className="absolute h-4 w-4 rounded-full bg-quotables-orange transform -translate-x-1/2 -translate-y-1/2 top-1/2"
          style={{ left: valuePercent }}
          {...bind()}
        />
      </div>
    </div>
  );
};

function Header({ decoClassName, children, rem }) {
  return (
    <>
      <icons.Decoration1
        className={`h-6 w-6 ${decoClassName}`}
        style={{ width: `${1.5 * rem}px`, height: `${1.5 * rem}px` }}
      />
      <h1
        className="font-semibold text-xl text-center"
        style={{ fontSize: `${1.25 * rem}px`, lineHeight: `${1.75 * rem}px` }}
      >
        {children}
      </h1>
      <icons.Decoration1
        className={`h-6 w-6 transform rotate-180 ${decoClassName}`}
        style={{ width: `${1.5 * rem}px`, height: `${1.5 * rem}px` }}
      />
    </>
  );
}

function ExpandButton({ className, rem, ...props }) {
  return (
    <span
      className={`p-2 rounded-full cursor-pointer ${className}`}
      style={{ padding: `${0.5 * rem}px` }}
      {...props}
    >
      <icons.Expand4
        className="h-4 w-4"
        style={{ width: `${1.0 * rem}px`, height: `${1.0 * rem}px` }}
      />
    </span>
  );
}

function TestWidget({ width, height, rem }) {
  return (
    <div
      className="flex flex-col rounded overflow-hidden shadow-2xl border border-quotables-light-gray border-opacity-50"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <div className="h-full text-quotables-blue bg-white flex flex-col">
        <div
          className="flex flex-row p-4 justify-between items-center cursor-move"
          style={{ padding: `${1.0 * rem}px` }}
        >
          <Header rem={rem}>Introducing Quotables</Header>
        </div>
        <div
          className="text-xs p-2 leading-relaxed text-quotables-light-gray cursor-pointer flex-grow text-center"
          style={{
            padding: `${0.5 * rem}px`,
            fontSize: `${0.75 * rem}px`,
            lineHeight: `${1.625 * rem}px`,
          }}
        >
          The Quotables are NFT versions of the traditional Dutch tile sayings,
          also known as “tegelspreuken”. These tiles have an interesting
          history, combining the art of tin-glazed pottery and the love for
          proverbs and aphorisms deeply rooted in Dutch culture. We're offering
          the experience of making your own tile with your favorite quote, turn
          in into an NFT and put it up on the Quotawall. Share the wisdom and
          spread the word!
        </div>
        <div
          className="flex flex-row pb-4 pr-4 items-center justify-end cursor-pointer"
          style={{
            paddingBottom: `${1.0 * rem}px`,
            paddingRight: `${1.0 * rem}px`,
          }}
        >
          <ExpandButton className="bg-quotables-blue text-white" rem={rem} />
        </div>
      </div>
    </div>
  );
}

const rel = { x: 1250, y: 705 };

const TestScreen = () => {
  const [value, setValue] = React.useState(640);
  const { w, h } = { w: 370, h: 280 };
  const win = { x: value, y: (value * rel.y) / rel.x };
  const r = { x: rel.x / win.x, y: rel.y / win.y };
  const size = { width: w / r.x, height: h / r.y };
  const fontSize = size.height * 0.05;
  return (
    <>
      <div className="flex flex-row">
        <input
          type="number"
          value={value}
          className="text-center"
          onChange={(e) => setValue(parseInt(e.target.value))}
        />
        <Scroll
          value={value}
          setValue={setValue}
          minValue={1}
          maxValue={5000}
        />
      </div>
      <div className="p-2">
        <TestWidget width={size.width} height={size.height} rem={fontSize} />
      </div>
    </>
  );
};

export default TestScreen;
