import * as React from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal } from '../components';
import * as api from '../api';

function TextField({ title, value, onChange, tabIndex = -1 }) {
  return (
    <div className="text-black focus-within:text-black border border-primary-gray m-1 w-full">
      <input
        type="text"
        className="w-full p-2 bg-quotables-light-blue focus:outline-none placeholder-quotables-light-gray"
        placeholder={title}
        value={value}
        onChange={onChange}
        tabIndex={tabIndex}
        autoFocus
      />
    </div>
  );
}

const emailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function useSubmit() {
  const [state, setState] = React.useState('initial');
  const callback = contacts => {
    setState('loading');
    api.addMailing(contacts)
      .then(r => setState('ready'))
      .catch(err => setState('error'));
  };
  return [state, callback];
}

export function Contacts({ onHide }) {
  const [contacts, setContacts] = React.useState({
    email: '',
  });
  const valid = emailRE.test(contacts.email);
  const [state, onSubmit] = useSubmit();
  React.useEffect(() => {
    if (state === 'ready') {
      onHide();
    }
  }, [state, onHide]);
  return (
    <div className="flex flex-col items-center h-40 w-full justify-between text-center">
      <h2 className="text-sm">
        Something new is always cooking! Subscribe to our newsletter to stay
        up-to-date about our future NFT projects.
      </h2>
      <TextField
        title="Email"
        value={contacts.email}
        onChange={(e) =>
          setContacts((value) => ({ ...value, email: e.target.value }))
        }
      />
      <button
        className="text-white bg-quotables-orange font-semibold py-2 px-4 w-40 uppercase disabled:opacity-50"
        onClick={() => onSubmit(contacts)}
        disabled={!valid || state === 'loading'}
      >
        Subscribe
      </button>
    </div>
  );
}

function Mailing() {
  const { currentModal } = React.useContext(StateContext);
  const { onHide } = React.useContext(DispatchContext);
  const isOpen = currentModal === 'mailing';
  return (
    <Modal isOpen={isOpen} onRequestClose={onHide}>
      <header className="flex flex-row justify-center items-center p-4 bg-quotables-blue text-white rounded-tl rounded-tr">
        <span className="font-semibold">Priority Mail</span>
      </header>
      <section className="p-6 text-xs text-quotables-gray leading-relaxed">
        <Contacts onHide={onHide} />
      </section>
    </Modal>
  );
}

export default Mailing;
