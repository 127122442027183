import { useContext, memo } from 'react';
import * as icons from '../icons';
import { DispatchContext } from '../context';
import { Widget, ExpandButton } from './Base';

function WalletWidget({ item: { width, height, rem }, dragRef, inline }) {
  const { showWalletModal } = useContext(DispatchContext);
  return (
    <Widget width={width} height={height} inline={inline}>
      <div
        className="cursor-move h-full py-2 px-4 text-quotables-blue bg-white flex flex-row items-center"
        ref={dragRef}
        style={{ padding: `${0.5 * rem}px ${1.0 * rem}px` }}
      >
        <button
          className="flex flex-row items-center focus:outline-none"
          onClick={showWalletModal}
        >
          <icons.Wallet
            className="h-6 w-6 mr-2"
            style={{
              width: `${1.5 * rem}px`,
              height: `${1.5 * rem}px`,
              marginRight: `${0.5 * rem}px`,
            }}
          />
          <h1
            className="uppercase font-semibold"
            style={{ fontSize: `${1.0 * rem}px` }}
          >
            Wallet
          </h1>
        </button>
        <div className="flex-grow" />
        <ExpandButton
          className="bg-quotables-blue text-white"
          onClick={showWalletModal}
          rem={rem}
        />
      </div>
    </Widget>
  );
}

export default memo(WalletWidget);
