import Particles from 'react-particles-js';

const particlesConfig = {
  particles: {
    number: {
      value: 160,
      density: {
        enable: false,
      },
    },
    color: {
      value: '#20205c',
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out',
    },
  },
};

function BubbleParticles({ className }) {
  return <Particles className={className} params={particlesConfig} />;
}

export default BubbleParticles;
