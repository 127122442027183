import React, { useState, useEffect } from 'react';
import * as api from '../api';
import { useContainerDimensions } from '../hooks';
import TilePreview from './TilePreview';
import Zoomable, { clamp } from './Zoomable';
import ReactTooltip from 'react-tooltip';
import OpenSeaLink from './OpenSeaLink';

export const range = (start, end) => {
  return Array.from({ length: end - start }, (_, i) => start + i);
};

const MIN_SCALE = 0.25;
const PREVIEW_SCALE = 5;
const MAX_SCALE = 6;

function Zoom({ previewId }) {
  const ref = React.useRef();
  const { width, height } = useContainerDimensions(ref);

  const nx = width > height ? 64 : 32;
  const ny = width > height ? 32 : 64;
  const size = Math.floor(width / (nx * MIN_SCALE));
  const tileMap = useTileMap();
  previewId = (previewId >= 1) && (+previewId <= 2048) ? +previewId : null;
  const initialZoom = previewId ? PREVIEW_SCALE : MIN_SCALE;
  const previewSize = size * PREVIEW_SCALE;
  const x = previewId ? (
    ((previewId - 1) % nx) * previewSize - (width - previewSize) / 2
  ) : 0;
  const y = previewId ? (
    (Math.floor((previewId - 1) / nx)) * previewSize - (height - previewSize) / 2
  ) : 0;
  return (
    <div className="w-full h-full" ref={ref}>
      {size ? (
      <Zoomable
        initialZoom={initialZoom}
        initialX={clamp(-x, -(nx * previewSize), 0)}
        initialY={clamp(-y, -(ny * previewSize), 0)}
        minZoom={MIN_SCALE}
        maxZoom={MAX_SCALE}
        width={size * nx}
        height={size * ny}
      >
        <div
          className="flex flex-wrap select-none pointer-events-none antialiased"
          style={{
            width: `${size * nx}px`,
            height: `${size * ny}px`,
          }}
        >
          {tileMap.map((tile, key) => (
            <TilePreview
              id={tile.id}
              key={key}
              size={size}
              text={tile.title}
              bg={tile.bg}
              scale="small"
            />
          ))}
        </div>
      </Zoomable>
      ) : null}
      <ReactTooltip
        getContent={(dataTip) => (
          <OpenSeaLink id={dataTip} className="hover:underline">
            Quotable {dataTip}/2048
          </OpenSeaLink>
        )}
        effect="solid"
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        border={true}
        type="light"
      />
    </div>
  );
}

const emptyMap = range(0, 2048).map((i) => ({
  id: i,
  bg: 0,
  title: '',
}));

export function useTileMap() {
  const [data, setData] = useState(emptyMap);
  useEffect(() => {
    api.getAllTiles().then((d) => {
      for (let i = d.length; i < 2048; i++) {
        d.push({
          id: i,
          bg: 0,
          title: '',
        });
      }
      setData(d);
      ReactTooltip.rebuild();
    });
  }, []);
  return data;
}

export default Zoom;
