import { useContext } from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal, TileOwned } from '../components';

function WalletModal() {
  const { currentModal } = useContext(StateContext);
  const { onHide } = useContext(DispatchContext);
  const isOpen = currentModal === 'wallet';
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onHide}
      className="w-2/3 h-2/3 flex flex-col"
    >
      <header className="flex flex-row justify-center p-4 bg-quotables-blue rounded-tl rounded-tr">
        <h2 className="font-semibold text-xl text-white uppercase">
          Your Quotables
        </h2>
      </header>
      <div className="overflow-y-scroll flex-grow">
        <TileOwned size={300} />
      </div>
    </Modal>
  );
}

export default WalletModal;
