import { memo } from 'react';
import Quotawall3D from '../assets/images/quotawall-3d.png';
import { Widget, BlueWindow } from './Base';

function ComingSoon({ item: { width, height, rem }, dragRef, inline }) {
  return (
    <Widget width={width} height={height} inline={inline}>
      <BlueWindow title="Coming soon" dragRef={dragRef} rem={rem}>
        <div
          className="w-full h-full text-quotables-blue bg-contain bg-no-repeat bg-right pl-12 pt-8 pr-2 pb-2 flex flex-col cursor-pointer"
          style={{
            backgroundImage: `url(${Quotawall3D})`,
            padding: `${2 * rem}px ${0.5 * rem}px ${0.5 * rem}px ${3 * rem}px`,
          }}
        >
          <h2
            className="font-semibold mb-2 w-1/2"
            style={{
              fontSize: `${1.0 * rem}px`,
              marginBottom: `${0.5 * rem}px`,
            }}
          >
            Immortalise yourself on the Quotawall
          </h2>
          <p
            className="text-xs text-quotables-light-gray leading-relaxed"
            style={{
              fontSize: `${0.75 * rem}px`,
              lineHeight: `${1.625 * rem}px`,
            }}
          >
            Co-create art & share
            <br />
            wisdom. Revive a piece
            <br />
            of history.
          </p>
          <div className="flex-grow" />
        </div>
      </BlueWindow>
    </Widget>
  );
}

export default memo(ComingSoon);
