import * as icons from '../icons';
import { Context } from '../context';
import { Modals } from '../modals';
import { Progress, Particles, Copyright, WidgetBoard } from '../components';
import { Types as WidgetTypes } from '../widgets';

const getRandomElement = (arr) => {
  const idx = Math.floor(Math.random() * arr.length);
  return arr[idx];
};

const widgetSize = (width, height) => ({ width, height });

// these are on 1250x705 screen
export const widgetRelSizes = {
  [WidgetTypes.FAQ]: { ...widgetSize(355, 200) },
  [WidgetTypes.Quotawall]: { ...widgetSize(385, 265) },
  [WidgetTypes.Social]: { ...widgetSize(50, 45) },
  [WidgetTypes.MetaMask]: { ...widgetSize(50, 45) },
  [WidgetTypes.Mailing]: { ...widgetSize(50, 45) },
  [WidgetTypes.Intro]: { ...widgetSize(370, 280) },
  [WidgetTypes.Wallet]: { ...widgetSize(370, 50) },
  [WidgetTypes.Buy]: { ...widgetSize(370, 50) },
  [WidgetTypes.RandomTile]: { ...widgetSize(265, 290) },
  [WidgetTypes.ComingSoon]: { ...widgetSize(385, 265) },
};

export const widgetRelAt = (x, y, type) => {
  const rel = { x: 1250, y: 705 };
  const c1 = { x: rel.x / 2, y: rel.y / 2 };

  const windowWidth = window.innerWidth;
  const win = { x: windowWidth, y: (windowWidth * rel.y) / rel.x };
  const c2 = { x: win.x / 2, y: win.y / 2 };

  const r = { x: rel.x / win.x, y: rel.y / win.y };
  const { width: w, height: h } = widgetRelSizes[type];
  const size = { width: w / r.x, height: h / r.y };

  const delta = { x: x - c1.x, y: y - c1.y };
  delta.x /= r.x;
  delta.y /= r.y;
  const n = { x: c2.x + delta.x, y: c2.y + delta.y };

  const fontSize = windowWidth * 0.010;
  return {
    top: n.y,
    left: n.x,
    width: size.width,
    height: size.height,
    rem: fontSize,
    type,
  };
};

// these are on 1250x705 screen
export const widgets = getRandomElement([
  [
    { ...widgetRelAt(815, 105, WidgetTypes.FAQ) },
    { ...widgetRelAt(415, 65, WidgetTypes.Quotawall) },
    { ...widgetRelAt(1110, 30, WidgetTypes.Social) },
    { ...widgetRelAt(1040, 30, WidgetTypes.MetaMask) },
    { ...widgetRelAt(965, 30, WidgetTypes.Mailing) },
    { ...widgetRelAt(80, 150, WidgetTypes.Intro) },
    { ...widgetRelAt(320, 430, WidgetTypes.Wallet) },
    { ...widgetRelAt(320, 375, WidgetTypes.Buy) },
    { ...widgetRelAt(780, 260, WidgetTypes.RandomTile) },
  ],
  [
    { ...widgetRelAt(730, 340, WidgetTypes.FAQ) },
    { ...widgetRelAt(155, 70, WidgetTypes.Quotawall) },
    { ...widgetRelAt(1110, 15, WidgetTypes.Social) },
    { ...widgetRelAt(1040, 15, WidgetTypes.MetaMask) },
    { ...widgetRelAt(965, 15, WidgetTypes.Mailing) },
    { ...widgetRelAt(70, 260, WidgetTypes.Intro) },
    { ...widgetRelAt(820, 215, WidgetTypes.Wallet) },
    { ...widgetRelAt(820, 160, WidgetTypes.Buy) },
    { ...widgetRelAt(550, 105, WidgetTypes.RandomTile) },
  ],
  [
    { ...widgetRelAt(820, 190, WidgetTypes.FAQ) },
    { ...widgetRelAt(450, 260, WidgetTypes.Quotawall) },
    { ...widgetRelAt(1135, 65, WidgetTypes.Social) },
    { ...widgetRelAt(1060, 65, WidgetTypes.MetaMask) },
    { ...widgetRelAt(995, 65, WidgetTypes.Mailing) },
    { ...widgetRelAt(85, 240, WidgetTypes.Intro) },
    { ...widgetRelAt(85, 165, WidgetTypes.Wallet) },
    { ...widgetRelAt(160, 105, WidgetTypes.Buy) },
    { ...widgetRelAt(545, 25, WidgetTypes.RandomTile) },
  ],
  [
    { ...widgetRelAt(815, 105, WidgetTypes.FAQ) },
    { ...widgetRelAt(445, 260, WidgetTypes.Quotawall) },
    { ...widgetRelAt(690, 30, WidgetTypes.Social) },
    { ...widgetRelAt(540, 30, WidgetTypes.MetaMask) },
    { ...widgetRelAt(615, 30, WidgetTypes.Mailing) },
    { ...widgetRelAt(90, 80, WidgetTypes.Intro) },
    { ...widgetRelAt(795, 315, WidgetTypes.Wallet) },
    { ...widgetRelAt(450, 175, WidgetTypes.Buy) },
    { ...widgetRelAt(150, 265, WidgetTypes.RandomTile) },
  ],
  [
    { ...widgetRelAt(745, 265, WidgetTypes.FAQ) },
    { ...widgetRelAt(410, 135, WidgetTypes.Quotawall) },
    { ...widgetRelAt(950, 30, WidgetTypes.Social) },
    { ...widgetRelAt(805, 30, WidgetTypes.MetaMask) },
    { ...widgetRelAt(880, 30, WidgetTypes.Mailing) },
    { ...widgetRelAt(75, 80, WidgetTypes.Intro) },
    { ...widgetRelAt(805, 170, WidgetTypes.Wallet) },
    { ...widgetRelAt(805, 100, WidgetTypes.Buy) },
    { ...widgetRelAt(115, 270, WidgetTypes.RandomTile) },
  ],
]).map((widget, i) => ({ ...widget, zIndex: i }));

function Windows(props) {
  return (
    <Context {...props}>
      <div className="bg-quotables-light-blue h-full w-full">
        <Particles className="absolute inset-0 bg-quotables-light-blue" />
        <icons.Brand className="absolute h-12 w-40 left-4 top-2" />
        <Progress className="absolute w-auto bottom-8 left-8 right-8 p-2 bg-white rounded shadow-xl" />
        <Copyright className="absolute bottom-px left-1/2 transform -translate-x-1/2 text-xs" />
        <WidgetBoard widgets={widgets} />
        <Modals />
      </div>
    </Context>
  );
}

export default Windows;
