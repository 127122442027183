import * as React from 'react';

function SvgMail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="Mail_svg__h-5 Mail_svg__w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
      <path
        fill="#fff"
        d="M3.01.37c-6.075 3.34-1.505 9.965 2.98 8.26C10.835 6.79 9.42-1.025 3.01.37z"
      />
      <path
        fill="#ED9121"
        d="M6.67 4.82v-.615c0-.08-.03-.155-.09-.215a.31.31 0 00-.22-.09H5.13V2.665c0-.08-.03-.155-.09-.215a.302.302 0 00-.22-.09h-.615c-.08 0-.155.03-.215.09a.299.299 0 00-.09.215V3.9H2.665c-.08 0-.155.03-.215.09a.299.299 0 00-.09.215v.615c0 .085.03.16.09.22s.135.09.215.09H3.9v1.23c0 .085.03.155.09.22.06.06.135.09.215.09h.615c.085 0 .16-.03.22-.09a.31.31 0 00.09-.22V5.13h1.23a.31.31 0 00.22-.09c.06-.06.09-.135.09-.22zm1.54-.305A3.691 3.691 0 014.515 8.21 3.691 3.691 0 01.82 4.515 3.691 3.691 0 014.515.82 3.691 3.691 0 018.21 4.515z"
      />
    </svg>
  );
}

export default SvgMail;
