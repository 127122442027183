import { default as Quotawall } from './Quotawall';
import { default as Faq } from './Faq';
import { default as Social } from './Social';
import { default as MetaMask } from './MetaMask';
import { default as Intro } from './Intro';
import { default as Wallet } from './Wallet';
import { default as Buy } from './Buy';
import { default as RandomTile } from './RandomTile';
import { default as Mailing } from './Mailing';
import { default as ComingSoon } from './ComingSoon';

export const Types = {
  Quotawall: 'Quotawall',
  FAQ: 'FAQ',
  Social: 'Social',
  MetaMask: 'MetaMask',
  Intro: 'Intro',
  Wallet: 'Wallet',
  Buy: 'Buy',
  RandomTile: 'RandomTile',
  Mailing: 'Mailing',
  ComingSoon: 'ComingSoon',
};

export function renderWidget(props) {
  const {
    item: { type },
  } = props;
  switch (type) {
    case Types.Quotawall:
      return <Quotawall {...props} />;
    case Types.FAQ:
      return <Faq {...props} />;
    case Types.Social:
      return <Social {...props} />;
    case Types.MetaMask:
      return <MetaMask {...props} />;
    case Types.Intro:
      return <Intro {...props} />;
    case Types.Wallet:
      return <Wallet {...props} />;
    case Types.Buy:
      return <Buy {...props} />;
    case Types.RandomTile:
      return <RandomTile {...props} />;
    case Types.Mailing:
      return <Mailing {...props} />;
    case Types.ComingSoon:
      return <ComingSoon {...props} />;
    default:
      return null;
  }
}

export {
  Quotawall,
  Faq,
  Social,
  MetaMask,
  Intro,
  Wallet,
  Buy,
  RandomTile,
  Mailing,
  ComingSoon,
};
