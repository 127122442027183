import { useContext } from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal } from '../components';

function BuySucceed() {
  const { currentModal } = useContext(StateContext);
  const { showWalletModal, onHide } = useContext(DispatchContext);
  const isOpen = currentModal === 'buy_succeed';
  return (
    <Modal isOpen={isOpen} onRequestClose={onHide}>
      <header className="flex flex-row justify-center items-center p-4 bg-quotables-blue text-white rounded-tl rounded-tr">
        <span className="font-semibold">Congrats</span>
      </header>
      <section className="p-4 flex flex-col justify-center items-center bg-white text-quotables-gray rounded-bl rounded-br">
        <span className="m-4 text-sm">You just bought a Quotable!</span>
        <div
          className="cursor-pointer text-white bg-quotables-orange rounded py-2 px-4"
          onClick={showWalletModal}
        >
          <span className="font-semibold uppercase">Go to Wallet</span>
        </div>
      </section>
    </Modal>
  );
}

export default BuySucceed;
