import * as React from 'react';

function SvgZoomOut(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.41 94.5" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1" fill="currentColor">
          <path
            d="M8.27 14.28c-.5.84-1 1.68-1.5 2.54a36.19 36.19 0 00-4.58 23A39.29 39.29 0 005.37 52a30 30 0 0014 14.25c10.23 5.22 20.87 3.25 23.1 2.79A38.16 38.16 0 0067.51 50.7a36.24 36.24 0 004.25-15.57h0c-.2-1.47-.16-3-.37-4.43a25.72 25.72 0 00-1.9-6.7 31.56 31.56 0 00-9.22-12.36 36.37 36.37 0 00-21.44-7.79 27 27 0 00-4.83.34c-5.6.81-10.48 3.39-15.27 6.18-1.28.74-2.51 1.55-3.88 2.23.75-.78 1.45-1.61 2.25-2.33 3.24-2.9 6.68-5.54 10.83-7a43.2 43.2 0 016.34-1.68A35.58 35.58 0 0139.92 1a30.88 30.88 0 0113.43 2.75 34.63 34.63 0 0112.51 8.82 35.38 35.38 0 017 12.3A33.56 33.56 0 0174 38.53a30.74 30.74 0 01-2.47 9.87A37.93 37.93 0 0159 64.26a34.81 34.81 0 01-11.1 5.32 39 39 0 01-12.29 1.34c-1.8-.08-3.61-.13-5.39-.38-8.6-1.16-16-4.6-21.67-11.37A30.49 30.49 0 012.48 47 44.36 44.36 0 011 34a34.54 34.54 0 014.65-16 9.94 9.94 0 012.21-3.4z"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={2}
            strokeLinecap="round"
          />
          <path
            d="M55.11 65.29c3.5 3.1 28.42 24.26 31.9 27.31a3 3 0 002.16.89 3 3 0 001.9-1.26 7 7 0 00-.26-8.54c-2.26-2.48-4.41-5.08-6.86-7.34-12.38-11.48-7.54-8-20-19.39z"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <path d="M16.88 36.17c3.44-.5 6.88-1 10.32-1.35 1.72-.2 3.44-.41 5.17-.57s3.44-.2 5.16-.24c3.45-.11 6.9-.13 10.35.1a91.6 91.6 0 0110.37 1.15 1.91 1.91 0 01.07 3.74A91.77 91.77 0 0148 40.54c-3.44.36-6.89.45-10.34.48-1.72 0-3.45 0-5.17-.06s-3.46-.25-5.18-.38c-3.46-.25-6.91-.59-10.37-1a1.73 1.73 0 01-.06-3.44z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgZoomOut;
