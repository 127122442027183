import { useContext } from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal, TileCreate } from '../components';

function BuyModal() {
  const { currentModal } = useContext(StateContext);
  const { onHide } = useContext(DispatchContext);
  const isOpen = currentModal === 'buy';
  return (
    <Modal isOpen={isOpen} onRequestClose={onHide}>
      <header className="flex flex-row justify-center p-4 bg-quotables-blue rounded-tl rounded-tr">
        <h2 className="font-semibold text-xl text-white uppercase">
          Make Your Quotable
        </h2>
      </header>
      <div className="p-4">
        <TileCreate />
      </div>
    </Modal>
  );
}

export default BuyModal;
