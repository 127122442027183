import * as React from 'react';

function SvgTimelineArrow(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.6 143.11"
      {...props}
    >
      <path
        d="M77.74 102.03c1.72-4.09 2.09-6.82 3.39-7 1.12-.11 2.17 1.79 2.61 2.62 9.51 17.78 13 33.87 14.32 36.26a2.41 2.41 0 01.36 1.8 2.31 2.31 0 01-1 1.18c-2.45 1.58-21.73 2.76-29.54 3.78-6.2.81-12.91 2.53-14 .29a1.59 1.59 0 01-.15-.73c0-4.8 12-11.61 13.7-19.74.94-4.59-2-4.57-6.94-21-.8-2.71-2-8.56-.64-12.47a16.52 16.52 0 015.45-7.55c3.28-2.41 6.69-3.07 11.39-2.8 13.29.75 19.87 2 23.44-.35 4.65-3 6.88-10.18 5-15.29-1.4-3.83-4.87-5.85-7.7-7.51-3.61-2.1-6.69-2.66-11.39-3.53a55.91 55.91 0 00-30.64 2.28c-8.41 3-15.51 7.59-24 11-5.13 2.09-10 4.39-16.31 3.18-6.64-1.29-12.79-5.09-14-13.19s4-16 10.88-20.5c3.87-2.52 7.47-3.32 11.15-5.61a13.4 13.4 0 004-4.38A85.62 85.62 0 0139.57 8.56c3.46-3.28 9.48-6.8 20.72-7.23a51.08 51.08 0 00-15.53 6.8 48.29 48.29 0 00-15.23 15.86c-.26.47-.77 1.4-.4 2.08.86 1.58 5.15-.77 10.49.72a11 11 0 016.36 4.23 9.7 9.7 0 011.56 4.64c.31 3.57-1.81 5.89-4.14 8a22.88 22.88 0 01-7.56 4.93 8.56 8.56 0 01-10-3.06 12.06 12.06 0 01-1.77-3.15c-1.74-5.46 2.29-10.53 1.06-11.36-.25-.17-.5 0-3 1-2.68 1.09-3.09 1.21-3.66 1.46C8.26 38.01 5.98 46.11 5.98 46.11c-2.22 7.06 1.24 12.62 7.7 14.84s12.11.07 17.89-2.38c7.59-3.21 14.58-7.52 22.3-10.19a81.13 81.13 0 0121.93-4.56c6.2-.23 13.4-.5 21.33 3.2 4.28 2 14.12 6.59 15.35 15.76 1 7.18-4 12.72-4.4 13.19-4.17 4.56-9.81 5.38-14.95 6-13.52 1.77-21.42-3.64-26.06 1.11a10.34 10.34 0 00-2.52 5.4c-.38 2.28-.15 4.76 2.23 11 2.65 6.93 4 10.14 5.72 10.39 1.9.34 3.6-3.96 5.24-7.84zM26.91 40.72c.35 1 1.27 3.7 3.76 4.77a5.74 5.74 0 003 .38c4.59-.55 7.43-3.06 9.57-6.93a5.9 5.9 0 00.52-3.24c-.29-3.12-3-5.62-5.64-6.62-3.65-1.37-8.22-.26-10.28 2.66a9.76 9.76 0 00-1.44 5.31 10 10 0 00.51 3.67z"
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgTimelineArrow;
