import { getErrorMessage } from '../blockchain';
import Backdrop from './Backdrop';

const Dialog = ({ onTryConnect, error }) => (
  <div className="bg-white rounded flex flex-col items-center justify-center w-80 p-4 shadow-xl">
    <span className="font-semibold">MetaMask is not connected</span>
    {error && (
      <span className="text-red-500 my-2 text-center">
        {getErrorMessage(error)}
      </span>
    )}
    <button
      className="text-white bg-quotables-orange py-2 px-4 mx-2 font-semibold"
      onClick={onTryConnect}
    >
      Connect
    </button>
  </div>
);

export const MetaMaskActivate = (props) => (
  <Backdrop>
    <Dialog {...props} />
  </Backdrop>
);

export default MetaMaskActivate;
