import { useContext } from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal, Zoom } from '../components';
import * as icons from '../icons';

function WallModal() {
  const { currentModal, previewId } = useContext(StateContext);
  const { onHide } = useContext(DispatchContext);
  const isOpen = currentModal === 'wall';
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onHide}
      className="w-5/6 h-5/6 flex flex-col bg-quotables-blue"
    >
      <header className="flex flex-row items-center justify-center p-4 bg-quotables-blue rounded-tl rounded-tr text-white">
        <div className="flex-grow" />
        <icons.Decoration1 className="h-4 w-4 transform rotate-180" />
        <h1 className="font-semibold text-xl text-center mx-4">Quotawall</h1>
        <icons.Decoration1 className="h-4 w-4" />
        <div className="flex-grow" />
        <button onClick={onHide}>
          <icons.Close className="h-4 w-4" />
        </button>
      </header>
      <div className="p-4 py-0 flex-grow rounded-bl rounded-br w-full h-full">
        <Zoom previewId={previewId} />
      </div>
    </Modal>
  );
}

export default WallModal;
