import * as React from 'react';

function SvgZoomIn(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.85 94.5" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1" fill="currentColor">
          <path
            d="M8.27 14.28c-.5.84-1 1.68-1.5 2.54a36.19 36.19 0 00-4.58 23A39.29 39.29 0 005.37 52a30 30 0 0014 14.25c10.23 5.22 20.87 3.25 23.1 2.79A38.16 38.16 0 0067.51 50.7a36.24 36.24 0 004.25-15.57h0c-.2-1.47-.16-3-.37-4.43a25.72 25.72 0 00-1.9-6.7 31.56 31.56 0 00-9.22-12.36 36.37 36.37 0 00-21.44-7.79 27 27 0 00-4.83.34c-5.6.81-10.48 3.39-15.27 6.18-1.28.74-2.51 1.55-3.88 2.23.75-.78 1.45-1.61 2.25-2.33 3.24-2.9 6.68-5.54 10.83-7a43.2 43.2 0 016.34-1.68A35.58 35.58 0 0139.92 1a30.88 30.88 0 0113.43 2.75 34.63 34.63 0 0112.51 8.82 35.38 35.38 0 017 12.3A33.56 33.56 0 0174 38.53a30.74 30.74 0 01-2.47 9.87A37.93 37.93 0 0159 64.26a34.81 34.81 0 01-11.1 5.32 39 39 0 01-12.29 1.34c-1.8-.08-3.61-.13-5.39-.38-8.6-1.16-16-4.6-21.67-11.37A30.49 30.49 0 012.48 47 44.36 44.36 0 011 34a34.54 34.54 0 014.65-16 9.94 9.94 0 012.21-3.4z"
            strokeLinecap="round"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <path d="M15.12 36c3.73-.3 7.47-.63 11.2-1 1.87-.21 3.74-.33 5.61-.68.93-.17 1.86-.36 2.79-.48a26.48 26.48 0 012.81-.17 95.82 95.82 0 0111.23.33A107.86 107.86 0 0160 35.34a1.8 1.8 0 01.07 3.53 108.08 108.08 0 01-11.2 1.76 93.66 93.66 0 01-11.21.75 26.36 26.36 0 01-2.81-.06c-.94-.09-1.88-.25-2.81-.38-1.88-.28-3.75-.33-5.63-.48-3.74-.27-7.49-.46-11.23-.62a1.92 1.92 0 01-.06-3.84z" />
          <path d="M35.64 16.38c.53 1.68 1 3.37 1.49 5.06s1 3.39 1.38 5.1.85 3.43 1.23 5.19c.09.45.19.84.27 1.36s.17 1 .22 1.48c.13 1 .2 1.88.24 2.8.05 1.83.09 3.62 0 5.39s-.36 3.52-.58 5.25l-.76 5.16c-.26 1.69-.54 3.42-.67 4.94v.15a1.67 1.67 0 01-1.81 1.53 1.7 1.7 0 01-1.5-1.29 33.77 33.77 0 01-.71-5.5c-.1-1.79-.14-3.55-.15-5.31s0-3.5.06-5.22.3-3.42.37-5.09c0-.82.1-1.65.11-2.43a10.46 10.46 0 000-1.13c0-.36-.07-.82-.11-1.23-.18-1.69-.41-3.41-.65-5.13l-1.47-10.4a1.56 1.56 0 011.33-1.77 1.58 1.58 0 011.71 1.09z" />
          <path
            d="M55.56 65.29C59.05 68.39 84 89.55 87.45 92.6a3.05 3.05 0 002.17.89 2.93 2.93 0 001.89-1.26 6.94 6.94 0 00-.26-8.54c-2.25-2.48-4.4-5.08-6.85-7.34-12.39-11.48-7.54-8-20-19.39z"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgZoomIn;
