import { useRef, useEffect } from 'react';
import { useContainerDimensions } from '../hooks';

const vertexShaderText = `
attribute vec2 uv;
attribute vec2 position;
varying vec2 vUV;

void main() {
  vUV = uv;
  gl_Position = vec4(position, 0, 1);
}
`;

const fragmentShaderText = `
#ifndef GL_FRAGMENT_PRECISION_HIGH
precision mediump float;
#else
precision highp float;
#endif

uniform vec3      iResolution;           // viewport resolution (in pixels)
uniform float     iTime;                 // shader playback time (in seconds)
uniform vec3      background;
uniform vec3      foreground;
varying vec2 vUV;

void mainImage( out vec4 fragColor, in vec2 fragCoord )
{
  float time = iTime / 4.0;

  // normalized pixel coordinates
  vec2 p = 4.0*fragCoord/iResolution.xy;

  // pattern
  float f = sin(p.x + sin(1.2*p.y + time)) +
            sin(length(p)+time) +
            sin(p.x*2.5+time);

  // color
  vec3 col = mix(foreground, background, smoothstep(-6.0, 3.0, f));

  // putput to screen
  fragColor = vec4(col,1.0);
}

void main() {
  mainImage(gl_FragColor, vUV.st * iResolution.xy);
}
`;

function createShader(gl, sourceCode, type) {
  // Compiles either a shader of type gl.VERTEX_SHADER or gl.FRAGMENT_SHADER
  var shader = gl.createShader(type);
  gl.shaderSource(shader, sourceCode);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    var info = gl.getShaderInfoLog(shader);
    throw new Error('Could not compile WebGL program. \n\n' + info);
  }
  return shader;
}

function createProgram(gl, vertexShader, fragmentShader) {
  var program = gl.createProgram();

  // Attach pre-existing shaders
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);

  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    var info = gl.getProgramInfoLog(program);
    throw new Error('Could not compile WebGL program. \n\n' + info);
  }
  return program;
}

const ShaderToy = (props) => {
  const containerRef = useRef();
  const canvasRef = useRef();
  const { width, height } = useContainerDimensions(containerRef);
  useEffect(() => {
    if (window.WebGLRenderingContext) {
      let canvas = canvasRef.current;
      let gl = canvas.getContext('webgl');
      if (gl) {
        let requestId,
          i = 0;

        gl.viewport(0, 0, width, height);

        let vs = createShader(gl, vertexShaderText, gl.VERTEX_SHADER);
        let fs = createShader(gl, fragmentShaderText, gl.FRAGMENT_SHADER);
        let pr = createProgram(gl, vs, fs);

        gl.useProgram(pr);
        let iTime = gl.getUniformLocation(pr, 'iTime'),
          iResolution = gl.getUniformLocation(pr, 'iResolution'),
          background = gl.getUniformLocation(pr, 'background'),
          foreground = gl.getUniformLocation(pr, 'foreground');

        let buf = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, buf);
        gl.bufferData(
          gl.ARRAY_BUFFER,
          new Float32Array([-1, -1, 0, 0, 3, -1, 2, 0, -1, 3, 0, 2]),
          gl.STATIC_DRAW,
        );

        let pos = gl.getAttribLocation(pr, 'position'),
          uv = gl.getAttribLocation(pr, 'uv');
        gl.vertexAttribPointer(
          pos,
          2,
          gl.FLOAT,
          false,
          4 * Float32Array.BYTES_PER_ELEMENT,
          0 * Float32Array.BYTES_PER_ELEMENT,
        );
        gl.vertexAttribPointer(
          uv,
          2,
          gl.FLOAT,
          false,
          4 * Float32Array.BYTES_PER_ELEMENT,
          2 * Float32Array.BYTES_PER_ELEMENT,
        );
        gl.enableVertexAttribArray(pos);
        gl.enableVertexAttribArray(uv);

        const render = () => {
          gl.uniform1f(iTime, i);
          gl.uniform3f(iResolution, width, height, 0);
          gl.uniform3f(foreground, 0x20 / 0xff, 0x20 / 0xff, 0x5c / 0xff);
          gl.uniform3f(background, 0xed / 0xff, 0xf0 / 0xff, 0xf5 / 0xff);
          gl.drawArrays(gl.TRIANGLES, 0, 3);
          i += 0.05;
          requestId = window.requestAnimationFrame(render);
        };
        render();
        return function cleanup() {
          window.cancelAnimationFrame(requestId);
        };
      }
    }
    console.log('No webgl :(');
  }, [width, height]);
  return (
    <div ref={containerRef} {...props}>
      <canvas width={width} height={height} ref={canvasRef} />
    </div>
  );
};

export default ShaderToy;
