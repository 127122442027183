import { useContext } from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal } from '../components';
import { Header } from './Base';

export function IntroContent() {
  return (
    <>
      <p className="mb-2">
        The Quotables are NFT versions of the traditional Dutch tile sayings,
        also known as "tegelspreuken". These tile sayings have an interesting
        history, combining the art of tin-glazed pottery and the love for
        proverbs and aphorisms deeply rooted in Dutch culture.
      </p>
      <p className="mb-2">
        Their role and popularity changed a lot throughout the centuries, from a
        sign of wealth, to being seen as outdated a few decades ago, and
        resurrecting as part of the current "kitsch revival". Today, most Dutch
        households have at least one of these tiles hanging on a wall somewhere.
        But it would be a shame to leave this tradition limited to the physical
        world. These quotes deserve more than a toilet wall.
      </p>
      <p className="mb-2">
        Wisdom should shared in all forms, physical, digital and on the
        blockchain! Which is why we created Quotables, a{' '}
        <span className="text-quotables-orange">limited series</span> of
        customizable NFT tile sayings. We're launching a total of{' '}
        <span className="text-quotables-orange">2048 Quotables</span>,
        containing customizable text quotes on different tile designs inspired
        from digital culture.
      </p>
      <p className="mb-2">
        Wisdom can be very effective in just a few words ;-). Each Quotable will
        have up to 128 characters dedicated to your favorite quote. To frame
        each quote accordingly, we prepared five different designs inspired from
        the traditional Dutch tiles.
      </p>
      <p className="mb-2">
        The 2048 Quotables will be featured on{' '}
        <span className="text-quotables-orange">the Quotawall</span>, paying
        respect to the tradition of wall-hanging wisdom.
      </p>
      <p className="mb-2">Join the Quotable movement.</p>
      <p className="font-semibold">
        Write it, frame it and let's make digital history!
      </p>
    </>
  );
}

export function IntroModal() {
  const { currentModal } = useContext(StateContext);
  const { onHide } = useContext(DispatchContext);
  const isOpen = currentModal === 'intro';
  return (
    <Modal isOpen={isOpen} onRequestClose={onHide}>
      <header className="flex flex-row justify-between mt-4 p-4">
        <Header decoClassName="text-quotables-orange">
          The Story of the Quotables
        </Header>
      </header>
      <section className="p-6 text-xs text-quotables-gray leading-relaxed text-justify max-h-80 overflow-y-scroll">
        <IntroContent />
      </section>
    </Modal>
  );
}

export default IntroModal;
