import { memo } from 'react';
import * as icons from '../icons';
import { Widget } from './Base';

function SocialWidget({ item: { width, height, rem }, dragRef, inline }) {
  return (
    <Widget width={width} height={height} inline={inline}>
      <div
        className="cursor-move h-full py-2 text-quotables-blue bg-white flex flex-row justify-evenly items-center"
        ref={dragRef}
        style={{ padding: `${0.5 * rem}px 0` }}
      >
        <button
          className="h-8 w-8 cursor-pointer focus:outline-none"
          style={{ width: `${2 * rem}px`, height: `${2 * rem}px` }}
        >
          <icons.Twitter />
        </button>
      </div>
    </Widget>
  );
}

export default memo(SocialWidget);
