import { useContext } from 'react';
import { StateContext, DispatchContext } from '../context';
import { Modal } from '../components';
import * as icons from '../icons';

const contractAddr = process.env.REACT_APP_CONTRACT_ADDR;
const contractUrl = `https://rinkeby.etherscan.io/address/${contractAddr}`;

function ContractLink(props) {
  return (
    <a href={contractUrl} target="_blank" rel="noreferrer" {...props}>
      {contractAddr}
    </a>
  );
}

export function FaqContent() {
  return (
    <>
      <div className="flex flex-row items-center">
        <div className="p-2">
          <icons.QuestionMark className="h-8 w-8 text-quotables-orange" />
        </div>
        <h2 className="text-sm font-semibold">How It Works</h2>
      </div>
      <section className="p-6 pt-2 text-quotables-gray text-justify">
        <p className="">
          There will be a total of 2048 Quotables, each co-created with its
          buyer. A Quotable can have one of the five different tile designs,
          chosen by the owner. The tile designs are all inspired from the
          traditional Dutch tiles in the Delft blue color. The buyer must type
          in his quote, then choose a tile design. In the meantime his tile
          preview is shown on the left side. The Quotable will only be purchased
          after pressing the Buy button, which will create the NFT and display
          it on the Quotawall. To ensure equal chances for the buyers, Quotables
          cannot be bought in bulk. A person can only create and buy one
          Quotable at a time.
        </p>
        <p className="mt-2">
          The sale begins at 15:00 on 26 March 2021 (CEST). The Quotables are
          being sold according to the schedule below.
        </p>
      </section>
      <div className="flex flex-row items-center">
        <div className="p-2">
          <icons.ExclamationMark className="h-8 w-8 text-quotables-orange" />
        </div>
        <h2 className="text-sm font-semibold">Rules for the Content</h2>
      </div>
      <section className="p-6 pt-2 text-quotables-gray text-justify">
        <p>Content No quote can be identical.</p>
        <p>There is a limit of 128 characters (including spaces.)</p>
        <p>
          Uniqueness is case insensitive (i.e. «John» and «john» are considered
          the same for the blockchain).
        </p>
        <p>There are no leading or trailing «spaces».</p>
        <p>Only alphanumerical symbols are eligible for use.</p>
      </section>
      <div className="flex flex-row items-center">
        <div className="p-2">
          <icons.CheckMark className="h-8 w-8 text-quotables-orange" />
        </div>
        <h2 className="text-sm font-semibold">Verified Smart Contract</h2>
      </div>
      <section className="p-6 pt-2 text-quotables-gray">
        <p>
          Quotables contract:{' '}
          <ContractLink className="text-quotables-orange hover:underline" />
        </p>
      </section>
    </>
  );
}

export function FaqModal() {
  const { currentModal } = useContext(StateContext);
  const { onHide } = useContext(DispatchContext);
  const isOpen = currentModal === 'faq';
  return (
    <Modal isOpen={isOpen} onRequestClose={onHide}>
      <header className="flex flex-row justify-center p-4 bg-quotables-blue rounded-tl rounded-tr">
        <h2 className="font-semibold text-xl text-white">FAQ</h2>
      </header>
      <div className="p-4 text-xs leading-relaxed max-h-80 overflow-y-scroll">
        <FaqContent />
      </div>
    </Modal>
  );
}

export default FaqModal;
