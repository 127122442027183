import * as React from 'react';

function SvgShare(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 135.35 80.39"
      {...props}
    >
      <path
        d="M75.88 18.41c-7.91-.21-12.72-.33-19.18-.21-13.18.26-20.32.4-29.59 2.73-13.36 3.34-18.63 8.18-20.83 10.22-6.58 6.13-8.08 13.45-4 21.46a33.6 33.6 0 0010.1 11.75c7.76 5.77 16.53 9.73 25.45 13.32a86.37 86.37 0 009.55 2.68c-1.83-.5-4.21-1.23-6.93-2.27a78.14 78.14 0 01-14.22-7.4c-4.67-3-7.61-5-10.81-8.53S9.64 55.7 9.16 51.35c-.72-6.45 3.59-11.41 4.55-12.52 3.49-4 7.52-5.23 14.79-7.4a58.14 58.14 0 018.93-2 111.83 111.83 0 0114.92-1h2.56c1.22 0 3.44 0 6.47.13 4.2.14 7.62.38 9 .47 6.3.44 11.79 1 18.77 1.71 2.61.25 6.31.6 10.81 1V19.36c-5.99-.3-14.23-.69-24.08-.95z"
        fill="currentColor"
      />
      <path
        d="M135.38 25.53c-.1-2.37-2.35-4.08-3.56-4.94-5.78-4.14-14.8-12-19.59-14.46-5.61-2.92-9.19-6.57-11.08-6.09-2.14.54-2.55 2.4-3 6.84a72.06 72.06 0 00-.12 9.06c.05 2.35 0 2.6 0 4.36h-.06v9.32h.4c0 4.39-.09 7.5-.21 9.62s-.19 4-.19 6c0 1.57.06 2.58.06 3.66s0 3.47 1.32 4.05c.94.43 2.2-.17 4-1.52 1.06-.81 1.9-1.43 2.65-2 2.07-1.46 2.43-1.64 5.69-3.89 2-1.37 3-2.15 4.53-3.27 7.05-5.17 9.75-6.19 14.7-10.54 3.76-3.37 4.46-4.78 4.46-6.2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgShare;
