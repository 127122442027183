import { useContext } from 'react';
import { StateContext } from '../context';
import { TimelineArrow, DecorationTimeline1 } from '../icons';

const clamp = (value, x, y) => Math.min(Math.max(value, x), y);
const prices = [
  { count: 250, flexGrow: 3, price: 0.01 },
  { count: 500, flexGrow: 6, price: 0.01 },
  { count: 500, flexGrow: 6, price: 0.01 },
  { count: 500, flexGrow: 6, price: 0.01 },
  { count: 200, flexGrow: 3, price: 0.01 },
  { count: 97, flexGrow: 2, price: 0.01 },
  { count: 1, flexGrow: 1, price: 0.01 },
];
const roundZeroDp = (value) => Math.floor(value).toFixed(0);

export function Progress({ num }) {
  const bars = prices.reduce((r, x) => {
    const left = r.length === 0 ? 0 : r[r.length - 1].right;
    const right = left + x.count;
    const value = clamp(((num - left) / (right - left)) * 100, 0, 100);
    const current = left <= num && num < right;
    return [...r, { left, right, value, current, ...x }];
  }, []);

  return (
    <div className="w-full flex flex-row text-quotables-blue text-xs">
      {bars.map((it, i) => (
        <div
          className="flex-grow flex flex-col items-center border border-white border-opacity-0"
          style={{ flexGrow: it.flexGrow }}
          key={i}
        >
          <span>
            {it.right - it.left}
            <br className="inline-block sm:hidden" /> NFTs
          </span>
          <div className="h-2 w-full my-4 bg-quotables-blue overflow-visible relative">
            {it.current && (
              <>
                <div
                  className="h-3 w-3 text-quotables-orange absolute transform -translate-x-1/2 -translate-y-4"
                  style={{ top: 0, left: `${roundZeroDp(it.value)}%` }}
                >
                  <DecorationTimeline1 className="animate-bounce" />
                </div>
                <div
                  className="h-3 w-3 text-quotables-orange absolute transform -translate-x-1/2 translate-y-4 rotate-180"
                  style={{ bottom: 0, left: `${roundZeroDp(it.value)}%` }}
                >
                  <DecorationTimeline1 className="animate-bounce" />
                </div>
              </>
            )}
            <div
              className="absolute bg-quotables-orange left-0 -inset-y-px"
              style={{ width: `${roundZeroDp(it.value)}%` }}
            />
          </div>
          <span>
            {it.price}
            <br className="inline-block sm:hidden" /> ETH
          </span>
        </div>
      ))}
    </div>
  );
}

export function ProgressFlat({ num }) {
  const percent = clamp((num * 100) / 2048, 1, 100);

  return (
    <div className="w-full flex flex-row text-quotables-blue text-xs">
      <div className="h-2 w-full my-4 bg-quotables-blue overflow-visible relative">
        <div
          className="h-3 w-3 text-quotables-orange absolute transform -translate-x-1/2 -translate-y-4"
          style={{ top: 0, left: `${roundZeroDp(percent)}%` }}
        >
          <DecorationTimeline1 className="animate-bounce" />
        </div>
        <div
          className="h-3 w-3 text-quotables-orange absolute transform -translate-x-1/2 translate-y-4 rotate-180"
          style={{ bottom: 0, left: `${roundZeroDp(percent)}%` }}
        >
          <DecorationTimeline1 className="animate-bounce" />
        </div>
        <div
          className="absolute bg-quotables-orange left-0 -inset-y-px"
          style={{ width: `${roundZeroDp(percent)}%` }}
        />
      </div>
    </div>
  );
}

function ProgressWithLabel({ className }) {
  const { numTiles } = useContext(StateContext);
  const num = numTiles || 0;
  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className="flex flex-row">
        <TimelineArrow className="h-12 w-6" />
        <h2 className="pl-1 text-xl font-semibold">
          Quotables Sale Progress{' '}
          <span className="">{num > 0 ? `${num}/2048` : ''}</span>
        </h2>
      </div>
      <Progress num={num} />
    </div>
  );
}

export function MobileProgress({ className }) {
  const { numTiles } = useContext(StateContext);
  const num = numTiles || 0;
  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className="flex flex-row">
        <TimelineArrow className="h-12 w-6" />
        <h2 className="pl-1 text-xl font-semibold">
          Quotables Sale Progress{' '}
          <span className="">{num > 0 ? `${num}/2048` : ''}</span>
        </h2>
      </div>
      <ProgressFlat num={num} />
    </div>
  );
}

export default ProgressWithLabel;
